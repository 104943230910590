.Calendar {
  font-family: "Poppins", sans-serif;
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.Calendar .Header {
  margin-left: 1rem;
  font-weight: bolder;
  font-size: large;
  margin-bottom: 3rem;
}
.select_event_modal_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  font-family: "Poppins", sans-serif;
  margin-top: 1rem;
}
.select_event_modal_container .element {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  font-size: large;
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5rem;
}
.select_event_modal_container #element_note {
  border-bottom: 0 !important;
}
.select_event_modal_container .element .element_label {
  font-weight: bold;
}
.Calendar .Calendarcontainer {
  display: flex;
  align-self: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 75rem;
  background-color: white;
  padding: 2rem;
  height: 100vh;
  border-radius: 12px;
  margin-bottom: 2rem;
}
.Calendar .Calendarcontainer .calendarcalendar {
  width: 100%;
}

@media (max-width: 1440px) {
  .Calendar .Calendarcontainer {
    width: 70rem;
    padding: 1rem;
  }
  .Calendar .Calendarcontainer .calendarcalendar {
    width: 90%;
  }
}
