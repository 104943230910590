@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
.Hero {
  height: 60vh;
  position: relative;
  font-family: "Poppins", sans-serif;
}
.Hero .Background_video {
  position: absolute;
  top: 0;
  z-index: -1;
  overflow: hidden;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.Hero .Content {
  color: black;
  font-size: 5rem;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  text-align: center;
}
.Hero .Content .Searchcontainer .Inputgroup {
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 5px solid rgba(156, 118, 118, 0.3);
}
.Hero .Content .Searchcontainer .Inputgroup .Input {
  background-color: white;
  border-radius: 25px;
  height: 3.5rem;
  color: black;
  font-size: x-large;
}
.Hero .Content .Searchcontainer .Inputgroup .Inputright {
  display: flex;
  flex-direction: column;
  height: 3.5rem;
}
.Hero .Content .Searchcontainer .Inputgroup .Inputright .Searchbtn {
  background-color: #23a994;
  width: 90px;
  border-radius: 25px;
}
.Hero .Content .Searchcontainer .Dropdown {
  position: absolute;
  margin-top: 0.3rem;
  width: 45rem;
  /*max-height: 15rem;*/
  max-height: fit-content;
  /*overflow-y: scroll;*/
  background-color: white;
  display: flex;
  border: 0.2px solid rgba(128, 128, 128, 0.121);
  flex-direction: column;
  border-radius: 12px;
  color: black;
  align-items: flex-start;
  /*padding-top: 1rem;*/
  padding-top: 0.3rem;
  padding-bottom: 1rem;
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
}
.Hero .Content .Searchcontainer .Dropdown::-webkit-scrollbar {
  border-radius: 12px;
  background-color: transparent;
  width: 10px;
  margin-right: 3px;
}
.Hero .Content .Searchcontainer .Dropdown::-webkit-scrollbar-thumb {
  border-radius: 12px;
  width: 10px;
  background-color: lightgray;
  margin-right: 3px;
}
.Hero .Content .Searchcontainer .Dropdown:empty {
  border: none;
  display: none;
}
.Hero .Content .Searchcontainer .Dropdown .Dropdownrow {
  display: flex;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  min-height: 3rem;
  border-bottom: 0.5px solid rgba(211, 211, 211, 0.471);
}
.Hero .Content .Searchcontainer .Dropdown .Dropdownrow:hover {
  background-color: #e8f6f4;
}
.Hero .Content .Searchcontainer .Dropdown .Dropdownrow .Dropdowntext {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  gap: 1rem;
  align-items: center;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 600px; /* Adjust as needed */
}
.Hero .Content .Searchcontainer .Dropdown .Dropdownrow .Dropdowntext .Avatar {
  align-self: center;
  margin-left: 10px;
}
.Hero .Content .Searchcontainer .Dropdown .Dropdownrow .Dropdownicon {
  font-size: 0.9rem;
  font-weight: 400;
  color: gray;
  align-self: center;
  margin-right: 1rem;
  margin-left: 1rem;
}
.Hero .Content .Searchcontainer .Dropdown .Dropdownrow .selectIcon {
  font-size: 0.9rem;
  font-weight: 400;
  color: gray;
  align-self: center;
  position: absolute;
  right: 10px;
}
@media (max-width: 1080px) {
  .Hero .Content {
    font-size: 4rem;
  }
  .Hero .Content .Searchcontainer .Dropdown .Dropdownrow .Dropdowntext {
    max-width: 600px; /* Adjust as needed */
  }
}
@media (max-width: 820px) {
  .Hero .Content {
    text-align: center;
  }
  .Hero .Content .Searchcontainer .Inputgroup {
    width: 35rem;
  }
  .Hero .Content .Searchcontainer .Dropdown {
    width: 35rem;
  }
  .Hero .Content .Searchcontainer .Dropdown .Dropdownrow .Dropdowntext {
    max-width: 400px; /* Adjust as needed */
  }
}
@media (max-width: 660px) {
  .header_text {
    font-size: 30px !important;
    line-height: 10px !important;
    text-align: center;
    align-self: center;
  }
  .Hero .Content .Searchcontainer .Inputgroup {
    width: 23rem;
  }
  .Hero .Content .Searchcontainer .Inputgroup .Input {
    font-size: 20px;
  }
  .Hero .Content .Searchcontainer .Dropdown {
    width: 23rem;
  }
  .Hero .Content .Searchcontainer .Dropdown .Dropdownrow .Dropdowntext {
    max-width: 250px; /* Adjust as needed */
  }
}
