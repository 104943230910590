.Staffs {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}
.Staffs .Header {
  font-weight: bolder;
  font-size: large;
}
.Staffs .Addstaffcontainer {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
}
.Staffs .Addstaffcontainer .Addstaffbtn {
  background-color: #23a994;
}
.Staffs .Stafflistcontainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  margin-top: 2rem;
}
.Staffs .Stafflistcontainer .Stafflistitem {
  background-color: white;
  border-radius: 12px;
  margin-bottom: 2rem;
  margin-right: 4rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
}
.Staffs .Stafflistcontainer .Stafflistitem .Staffimg {
  width: 7rem;
  height: 7rem;
  border-radius: 12px;
  display: flex;
  flex: 0.2;
}
.Staffs .Stafflistcontainer .Stafflistitem .Staffinfo {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 0.5rem;
  flex: 0.7;
}
.Staffs .Stafflistcontainer .Stafflistitem .Staffinfo .Staffname {
  font-weight: bold;
}
.Staffs .Stafflistcontainer .Stafflistitem .Staffactions {
  display: flex;
  align-self: flex-start;
  flex: 0.1;
}
.Staffs .emptyStaffs {
  width: 200%;
  text-align: center;
  align-items: center;
  align-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.Modelbtn {
  background-color: #23a994 !important;
}

.selectServicesBtn {
  background: #edf2f7;
  color: #718096;
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  border-radius: 0.5rem;
}
.selectServiceGrp {
  font-weight: 600;
  font-size: large;
}
.popupCheckServices {
  position: absolute;
  z-index: 90;
  width: 100%;
  margin: auto;
  background: #edf2f7;
  padding: 0.4rem;
  max-height: 12rem;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.5rem;
}
.checkServices {
  width: 99%;
  text-align: left;
  padding-left: 2rem;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 0.5rem;
}
.checkServices:hover {
  background: white;
}
.popupCheckServices::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Uploadimgcontainer {
  margin-bottom: 1rem;
  display: flex !important;
  flex-direction: row !important;
}
.Uploadimgcontainer .Image {
  border-radius: 12px;
}
.Uploadimgcontainer .Uploadimg {
  background-color: #23a994 !important;
  align-self: center;
  margin-left: 1.5rem;
}
.Uploadimgcontainer .Deleteimg {
  align-self: center;
  margin-left: 2rem;
  font-weight: 600;
  cursor: pointer;
}
@media (max-width: 940px) {
  .Staffs .Stafflistcontainer {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.Emptylistcontainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  width: max-content;
  height: max-content;
  background-color: white;
  padding: 3rem;
  border-radius: 12px;
  margin-top: 5rem;
  box-shadow: 0px 4px 16px rgba(4, 17, 15, 0.16);
}
.Emptylistcontainer .Emptyicon {
  width: 5rem !important;
  height: 5rem !important;
  color: rgba(128, 128, 128, 0.419) !important;
  margin-bottom: 2rem;
}
.Emptylistcontainer .Emptyheader {
  font-size: x-large;
  color: gray;
  font-weight: bold;
  margin-bottom: 1rem;
}
.Emptylistcontainer .Emptytext {
  color: gray;
}
.Error {
  border-color: red 2px solid;
}
