.LoginClient {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

.LoginClient .Left {
  display: flex;
  flex: 0.58;
}
.LoginClient .Left .logo_container {
  margin-left: 1rem;
}
.LoginClient .Left .logo {
  min-width: 13rem;
  max-width: 13rem;
  margin-top: 0.5rem;
}

.LoginClient .Right {
  display: flex;
  flex: 0.42;
}

.LoginClient .Left .OrChoice {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33rem;
  margin-left: 7rem;
  margin-top: 1rem;
}

.LoginClient .Left .choiceBtns {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
  width: 33rem;
  margin-left: 7rem;
  margin-top: 0.3rem;
}
.LoginClient .Left .registerText {
  display: flex;
  align-items: center;
  margin-left: 7rem;
  margin-top: 0.5rem;
  width: 33rem;
  justify-content: center;
}
.LoginClient .Left .registerText .Link {
  cursor: pointer;
  margin-left: 0.3rem;
}
.LoginClient .Left .registerText .Link:hover {
  text-decoration: underline;
}
.socialBtn {
  width: 20rem !important;
  text-align: center !important;
  text-align-last: center !important;
}

.LoginClient .Left .msg hr {
  width: 18rem;
  border-top: 1px solid rgb(169, 168, 168);
  margin-bottom: 1.2rem;
}

.LoginClient .Left .OrChoice hr {
  width: 5rem;
  border-top: 1px solid rgb(169, 168, 168);
  margin-inline: 0.3rem;
}

.LoginClient .Left .msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33rem;
  margin-left: 7rem;
  margin-top: 1.5rem;
}

.LoginClient .Left .btn_social {
  padding: 1.6rem;
  margin-inline: 1.2rem;
  width: 48%;
  border: 1px solid black;
  font-size: large;
}

.LoginClient .Right {
  width: 100%;
  max-height: 100vh;
}

.Right .Imagecontainer {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
      180deg,
      rgba(36, 169, 148, 0.4) -2.06%,
      #000000 150.42%
    ),
    url("/public/assets/Login/login_right.jpg");
  background-size: cover;
  background-position: 5%;
  display: flex;
  padding-bottom: 10%;
  padding-left: 10%;
  padding-right: 10%;
}

.Right .Imagecontainer .Headerscontainer {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}

.Right .Imagecontainer .Headerscontainer .Header {
  color: white;
  font-size: 2vw;
}

.Right .Imagecontainer .Headerscontainer .Subheader {
  color: white;
  font-size: 1vw;
}

.LoginClient .Left {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.LoginClient .Left .Logocontainer {
  width: 13rem;
  margin-left: 4rem;
  margin-top: 1rem;
}

.LoginClient .Left .Header {
  margin-left: 7rem;
  font-weight: 600;
  font-size: xx-large;
  margin-top: 4rem;
}

.LoginClient .Left .Subheader {
  margin-left: 7rem;
  margin-top: 1rem;
  color: #4d4d4d;
}

.LoginClient .Left .Label {
  margin-left: 7rem;
  margin-top: 2rem;
}

.LoginClient .Left .Emailinput {
  width: 33rem;
  margin-left: 7rem;
  margin-top: 0.5rem;
}

.LoginClient .Left .Passlabel {
  margin-left: 7rem;
  margin-top: 1rem;
}

.LoginClient .Left .Passinput {
  width: 33rem;
  margin-left: 7rem;
  margin-top: 0.5rem;
}

.LoginClient .Left .Submitbtn {
  width: 33rem;
  margin-left: 7rem;
  margin-top: 2rem;
  background-color: #23a994;
}

.LoginClient .Left .Fergotpass {
  width: 20rem;
  height: 1rem;
  margin-top: 1rem;
  margin-left: 7rem;
  text-decoration: none;
}

.LoginClient .Password {
  width: 33rem;
  height: 3rem !important;
}

.LoginClient .ShowHide {
  margin-top: 0.5rem;
}

@media (max-width: 1400px) {
  .LoginClient .Left {
    display: flex;
    flex: 0.55;
  }
  .LoginClient .Right {
    display: flex;
    flex: 0.45;
  }
  .LoginClient .Left .Logocontainer {
    width: 11rem;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .LoginClient .Left .Header {
    margin-left: 4rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }

  .LoginClient .Left .Subheader {
    margin-left: 4rem;
    margin-top: 1rem;
    color: #4d4d4d;
  }

  .LoginClient .Left .Label {
    margin-left: 4rem;
    margin-top: 2rem;
  }

  .LoginClient .Left .Emailinput {
    width: 28rem;
    margin-left: 4rem;
    margin-top: 0.5rem;
  }

  .LoginClient .Left .Passlabel {
    margin-left: 4rem;
    margin-top: 1rem;
  }

  .LoginClient .Left .Passinput {
    width: 28rem;
    margin-left: 4rem;
    margin-top: 0.5rem;
  }

  .LoginClient .Left .Submitbtn {
    width: 28rem;
    margin-left: 4rem;
    margin-top: 2rem;
    background-color: #23a994;
  }

  .LoginClient .Left .Fergotpass {
    width: 20rem;

    height: 1rem;
    margin-top: 1rem;
    margin-left: 4rem;
    text-decoration: none;
  }
  .LoginClient .Left .registerText {
    display: flex;
    align-items: center;
    margin-left: 4rem;
    margin-top: 0.5rem;
    width: 28rem;
    justify-content: center;
  }
}
@media (max-width: 1100px) {
  .LoginClient .Left {
    display: flex;
    flex: 0.55;
  }
  .LoginClient .Right {
    display: flex;
    flex: 0.45;
  }
  .LoginClient .Left .Logocontainer {
    width: 11rem;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .LoginClient .Left .Header {
    margin-left: 2rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }

  .LoginClient .Left .Subheader {
    margin-left: 2rem;
    margin-top: 1rem;
    color: #4d4d4d;
  }

  .LoginClient .Left .Label {
    margin-left: 2rem;
    margin-top: 2rem;
  }

  .LoginClient .Left .Emailinput {
    width: 28rem;
    margin-left: 2rem;
    margin-top: 0.5rem;
  }

  .LoginClient .Left .Passlabel {
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .LoginClient .Left .Passinput {
    width: 28rem;
    margin-left: 2rem;
    margin-top: 0.5rem;
  }

  .LoginClient .Left .Submitbtn {
    width: 28rem;
    margin-left: 2rem;
    margin-top: 2rem;
    background-color: #23a994;
  }

  .LoginClient .Left .Fergotpass {
    width: 20rem;

    height: 1rem;
    margin-top: 1rem;
    margin-left: 2rem;
    text-decoration: none;
  }
  .LoginClient .Left .registerText {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    margin-top: 0.5rem;
    width: 28rem;
    justify-content: center;
  }
}
@media (max-width: 940px) {
  .LoginClient .Left {
    display: flex;
    flex: 0.55;
  }
  .LoginClient .Right {
    display: none;
  }
  .LoginClient .Left .Logocontainer {
    width: 11rem;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .LoginClient .Left .Header {
    margin-left: 4rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }

  .LoginClient .Left .Subheader {
    margin-left: 4rem;
    margin-top: 1rem;
    color: #4d4d4d;
  }

  .LoginClient .Left .Label {
    margin-left: 4rem;
    margin-top: 2rem;
  }

  .LoginClient .Left .Emailinput {
    width: 28rem;
    margin-left: 4rem;
    margin-top: 0.5rem;
  }

  .LoginClient .Left .Passlabel {
    margin-left: 4rem;
    margin-top: 1rem;
  }

  .LoginClient .Left .Passinput {
    width: 28rem;
    margin-left: 4rem;
    margin-top: 0.5rem;
  }

  .LoginClient .Left .Submitbtn {
    width: 28rem;
    margin-left: 4rem;
    margin-top: 2rem;
    background-color: #23a994;
  }

  .LoginClient .Left .Fergotpass {
    width: 20rem;

    height: 1rem;
    margin-top: 1rem;
    margin-left: 4rem;
    text-decoration: none;
  }
  .LoginClient .Left .registerText {
    display: flex;
    align-items: center;
    margin-left: 4rem;
    margin-top: 0.5rem;
    width: 28rem;
    justify-content: center;
  }
}
@media (max-width: 560px) {
  .LoginClient .Right {
    display: none;
  }
  .LoginClient .Left .Logocontainer {
    margin-left: 0.5rem;
  }

  .LoginClient .Left .Header {
    font-size: x-large;
    margin-left: 1rem;
  }

  .LoginClient .Left .Subheader {
    margin-left: 1rem;
  }

  .LoginClient .Left .Label {
    margin-left: 0.5rem;
  }

  .LoginClient .Left .Emailinput {
    width: 95vw;
    margin: auto;
    margin-left: 1%;
  }

  .LoginClient .Left .Passlabel {
    width: 95vw;
    margin-left: 0.5rem;
  }

  .LoginClient .Left .Passinput {
    width: 95vw;
    margin: auto;
  }

  .LoginClient .Left .Fergotpass {
    width: 95vw;
    margin: auto;
    font-size: small;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }

  .LoginClient .Left .Submitbtn {
    width: 95vw;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: 0.5rem;
  }

  .LoginClient .Left .OrChoice {
    width: 94vw;
    margin: auto;
  }

  .LoginClient .Left .choiceBtns {
    width: 95vw;
    margin: auto;
    margin-top: 1rem;
  }

  .LoginClient .Left .msg hr {
    width: 80vw;
    border-top: 1px solid rgb(169, 168, 168);
    margin-bottom: 1.2rem;
  }

  .LoginClient .Left .OrChoice hr {
    width: 6rem;
    border-top: 1px solid rgb(169, 168, 168);
    /* margin-bottom: 1.2rem; */
    margin-inline: 0.3rem;
  }

  .LoginClient .Left .msg {
    width: 95vw;
    margin: auto;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .LoginClient .Left .btn_social {
    padding: 1.6rem;
    margin-inline: 1.2rem;
    width: 48%;
    border: 1px solid black;
    font-size: large;
  }
  .LoginClient .Left .registerText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
    margin-top: 0.5rem;
    justify-content: center;
  }
}
