.Bookmarks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 2rem;
  min-height: 60vh;
  font-family: "Poppins", sans-serif;
}

.Bookmarks .Cardssection {
  display: grid;
  gap: 50px 100px;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  gap: 0.1rem;
  width: fit-content;
  justify-content: flex-start;
}

.Bookmarks .Cardssection .Card {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 0 0 24.333333%;
  width: 500px;
  min-height: 15rem;
  background-color: #fff;
  position: relative;
}

.Bookmarks .Cardssection .Card .Carousel {
  display: flex;
  height: 8rem;
  width: 100%;
}

.Bookmarks
  .Salonscontainer
  .Salons
  .Cardssection
  .Card
  .Carousel:hover
  .swiper-button-prev {
  display: flex;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-position: center;
  background-image: url("./Icons/previous.png");
}

.Bookmarks .Cardssection .Card .Carousel:hover .swiper-button-next {
  display: flex;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-position: center;
  background-image: url("./Icons/previous.png");
  transform: scaleX(-1);
}

.Bookmarks .Cardssection .Card .Carousel .swiper-button-prev {
  display: none;
}

.Bookmarks .Cardssection .Card .Carousel .swiper-button-prev::after {
  display: none;
}

.Bookmarks .Cardssection .Card .Carousel .swiper-button-next {
  display: none;
}

.Bookmarks .Cardssection .Card .Carousel .swiper-button-next::after {
  display: none;
}

.Bookmarks .Cardssection .Card .Info {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.7rem;
}

.Bookmarks .Cardssection .Card .Info .Titleinfo {
  display: flex;
  flex-direction: column;
}

.Bookmarks .Cardssection .Card .Info .Titleinfo .Title {
  font-size: large;
  font-weight: bold;
}

.Bookmarks .Cardssection .Card .Info .Titleinfo .Rating {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  align-items: center;
}

.Bookmarks .Cardssection .Card .Info .Titleinfo .Placement {
  font-weight: bold;
  color: #23a994;
}

.Bookmarks .title {
  width: 100%;
  padding-left: 5rem;
  font-weight: bold;
  font-size: 2.5rem;
}

.Bookmarks .noCards {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 5rem;
  margin-top: 2rem;
  font-size: 1.2rem;
}

.Bookmarks .Card .Carousel {
  display: flex;
  height: 8rem;
  width: 100%;
}

.Bookmarks .Card .Carousel:hover .swiper-button-prev {
  display: flex;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-position: center;
  background-image: url("./Icons/previous.png");
}

.Bookmarks .Card .Carousel:hover .swiper-button-next {
  display: flex;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-position: center;
  background-image: url("./Icons/previous.png");
  transform: scaleX(-1);
}

.Bookmarks .Card .Carousel .swiper-button-prev {
  display: none;
}

.Bookmarks .Card .Carousel .swiper-button-prev::after {
  display: none;
}

.Bookmarks .Card .Carousel .swiper-button-next {
  display: none;
}

.Bookmarks .Card .Carousel .swiper-button-next::after {
  display: none;
}

.Bookmarks .Card .Info {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.7rem;
}

.Bookmarks .Card .Info .Titleinfo {
  display: flex;
  flex-direction: column;
}

.Bookmarks .Card .Info .Titleinfo .Title {
  font-size: large;
  font-weight: bold;
}

.Bookmarks .Card .Info .Titleinfo .Rating {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  align-items: center;
}

.Bookmarks .Card .Info .Titleinfo .Placement {
  font-weight: bold;
  color: #23a994;
}

.Bookmarks .noCards h2 {
  font-weight: 600;
  margin-bottom: 0.4rem;
}

.Bookmarks .noCards .bookmarkBtn {
  margin-top: 1rem;
  background: #23a994;
  color: white;
  width: fit-content;
}

.Bookmarks .Card:hover .btnX {
  position: absolute;
  z-index: 50;
  right: -5px;
  top: -5px;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  background: rgb(154, 153, 153);
  color: #fff;
  opacity: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.Bookmarks .Card .btnX {
  display: none;
}

@media (max-width: 1200px) {
  .Bookmarks .Cardssection {
    display: grid;
    gap: 50px 100px;
    grid-template-columns: repeat(1, 1fr);
    flex-wrap: wrap;
    gap: 0.1rem;
    width: fit-content;
    justify-content: flex-start;
  }
}

@media (max-width: 580px) {
  .Bookmarks .Cardssection .Card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 0 0 0;
    width: 95%;
    min-height: 15rem;
    background-color: #fff;
    position: relative;
    align-self: start;
    margin: auto;
    margin-bottom: 0.7rem;
  }

  .Bookmarks .title {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: bold;
    font-size: 2.5rem;
  }

  .Bookmarks .noCards {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2rem;
    font-size: 1.2rem;
  }
}
