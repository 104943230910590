.menu{
    width: 30%;
    position: sticky;
    top: 160px;
    height: fit-content;
}
.infos{
    width: 60%;
    right: 30px;
    padding: 25px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

}
.menu li{
    list-style-type: none;
    padding: 25px;
    border-radius: 10px;
    width: 85%;
    display: flex;
    align-items: center;
    font-weight: 600;
}
.active{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.question{
 font-weight: 500;
 font-size: 1.2rem;
}

.response{
 margin-top: 8px;
 font-weight: 400;
 margin-bottom: 24px;
}
.paragraph{
    margin-bottom: 7px;
    
}
.infosMobile,.menuMobile{
    display: none;
}
@media (max-width: 1060px) {
    .infos{
        display: none;
    }
    .menu{
        display: none;
    }
    .infosMobile{
        display: block;
        top: 100px;
        width: 99vw;
        margin: auto;
        background: white;
        
    }
    .menuMobile{
        display: block;
        width: 80%;
        position: sticky;
        top: 160px;
        height: fit-content;
    }
    .menuMobile li{
        list-style-type: none;
        padding: 25px;
        border-radius: 10px;
        width: 98%;
        display: flex;
        align-items: center;
        font-weight: 600;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin-bottom: 10px ;
    }
}