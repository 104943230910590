.Topnaviguation {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 4rem;
  padding: 0.5rem;
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: white;
  font-family: "Poppins", sans-serif;
}
.Topnaviguation .Leftside {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0.5;
  gap: 1rem;
}
.Topnaviguation .Leftside .Logo {
  min-width: 13rem;
  max-width: 13rem;
  align-self: flex-start;
}
.Topnaviguation .Leftside .Switchcontaner {
  position: relative;
  width: 10rem;
  height: 2rem;
  border-radius: 25px;
}
.Topnaviguation .Leftside .Switchcontaner input {
  appearance: none;
  width: 10rem;
  height: 2.5rem;
  border-radius: 25px;
  /*border: 1px solid black;*/
  background: #edf2f7;
  outline: none;
  cursor: pointer;
}
.Topnaviguation .Leftside .Switchcontaner input::before,
.Topnaviguation .Leftside .Switchcontaner input::after {
  z-index: 2;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  font-weight: bold;
}
.Topnaviguation .Leftside .Switchcontaner input::before {
  content: "Kunde";
  left: 20px;
}
.Topnaviguation .Leftside .Switchcontaner input::after {
  content: "Partner";
  right: 15px;
}
.Topnaviguation .Leftside .Switchcontaner input:checked {
  background: #e8f6f4;
}
.Topnaviguation .Leftside .Switchcontaner label {
  z-index: 1;
  position: absolute;
  top: 6px;
  bottom: -3px;
  border-radius: 20px;
}
.Topnaviguation .Leftside .Switchcontaner input {
  transition: 0.25s;
}
.Topnaviguation .Leftside .Switchcontaner input:checked::after,
.Topnaviguation .Leftside .Switchcontaner input:checked::before {
  color: black;
  transition: color 0.5s;
}
.Topnaviguation .Leftside .Switchcontaner input:checked + label {
  left: 10px;
  right: 80px;
  transition: left 0.5s, right 0.4s 0.2s;
  background: #23a994;
}
.Topnaviguation .Leftside .Switchcontaner input:not(:checked) {
  background: #e8f6f4;
  transition: background 0.4s;
}
.Topnaviguation .Leftside .Switchcontaner input:not(:checked)::before {
  color: black;
  transition: color 0.5s;
}
.Topnaviguation .Leftside .Switchcontaner input:not(:checked)::after {
  color: #1e1e1e;
  transition: color 0.5s 0.2s;
}
.Topnaviguation .Leftside .Switchcontaner input:not(:checked) + label {
  left: 80px;
  right: 10px;
  background: #23a994;
  transition: left 0.4s 0.2s, right 0.5s, background 0.35s;
}
.Topnaviguation .Rightside {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0.5;
  justify-content: flex-end;
  gap: 1rem;
}
.Topnaviguation .Rightside .Logincontainer {
  display: flex;
  align-self: center;
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
}
.Topnaviguation .Rightside .Logincontainer .Loginicon {
  display: flex;
  align-self: center;
  justify-self: center;
  margin-right: 0.5rem;
}
.Topnaviguation .Rightside .Logincontainer .Loginicon .Icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #23a994;
}
.Item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
  margin-top: 2rem;
}
.Item .Loginicon {
  display: flex;
  align-self: center;
  justify-self: center;
}
.Item .Loginicon .Icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #23a994;
}
.Profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-size: larger;
  font-weight: 700;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.Profile:hover {
  background-color: #f7f7fa;
  border-radius: 12px;
}
.subscription_modal_container {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.subscription_modal_container .subscription_modal_icon_container {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subscription_modal_container
  .subscription_modal_icon_container
  .subscription_modal_icon {
  width: 12rem;
}
.subscription_modal_container .subscription_modal_title {
  margin-top: 1rem;
  font-size: 2.5rem;
}
.subscription_modal_container .subscription_modal_subtitle {
  color: #23a994;
}
.subscription_modal_container .subscription_modal_form {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
@media (max-width: 880px) {
  .Topnaviguation .Leftside .Logo {
    display: none;
  }
}
