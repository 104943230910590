.Planpanel {
  display: flex;
  flex-direction: column;
  height: max-content;
  margin-bottom: 3rem;
}
.Planpanel .Panelheader {
  font-weight: bolder;
  font-size: 2rem;
  text-shadow: 0px 1px, 1px 0px, 1px 1px;
  align-self: center;
  margin-bottom: 3rem;
  text-align: center;
}
.Planpanel .Panelbody {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-self: center;
}
.Planpanel .Panelbody .Starterplan {
  border: 1px solid black;
  padding-left: 5rem;
  border-radius: 12px;
  padding-right: 5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
}
.Planpanel .Panelbody .Upgradeplan {
  border: 1px solid black;
  padding-left: 5rem;
  border-radius: 12px;
  padding-right: 5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
}
.Planpanel .Panelbody .Starterplan .Starterheader {
  font-weight: bolder;
  font-size: xx-large;
  margin-bottom: 1.5rem;
  text-align: center;
}
.Planpanel .Panelbody .Starterplan .Starterammount {
  text-align: center;
  margin-bottom: 1.5rem;
}
.Planpanel .Panelbody .Starterplan .Starterammount .bold {
  font-size: 2rem;
  font-weight: bolder;
}
.Planpanel .Panelbody .Starterplan .Plansteps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: justify;
}
.Planpanel .Panelbody .Starterplan .Plansteps .Step {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  align-content: center;
  font-weight: bold;
}

.Planpanel .Panelbody .Upgradeplan .Starterheader {
  font-weight: bolder;
  font-size: xx-large;
  margin-bottom: 1.5rem;
  text-align: center;
}
.Planpanel .Panelbody .Upgradeplan .Starterammount {
  text-align: center;
  margin-bottom: 1.5rem;
}
.Planpanel .Panelbody .Upgradeplan .Starterammount .bold {
  font-size: 2rem;
  font-weight: bolder;
}
.Planpanel .Panelbody .Upgradeplan .Plansteps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: justify;
}
.Planpanel .Panelbody .Upgradeplan .Plansteps .Step {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  align-content: center;
  font-weight: bold;
}
@media (max-width: 780px) {
  .Planpanel .Panelbody {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-self: center;
  }
}
