.Stats {
  height: 40vh;
  background: #eaf7f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.Stats .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Stats .item .number {
  font-weight: bolder;
  font-size: 3.5rem;
  color: #fe6b28;
  text-shadow: 0px 1px, 1px 0px, 1px 1px;
}
.Stats .item .title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1.1rem;
}
.Stats .item .paragraph {
  text-align: center;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-justify: distribute;
}
@media (max-width: 760px) {
  .Stats {
    height: fit-content;
    flex-direction: column;
    justify-content: center;
  }
  .Stats .item {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}
