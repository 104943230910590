.Support {
  background-color: white;
  width: 100%;
  height: max-content;
  min-height: 50vh;
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  gap: 1.5rem;
}
.Support .Supportheader {
  font-weight: bold;
}
.Support .Updatebtn {
  background-color: #23a994;
  width: 10rem;
}
.Textarea {
  height: 15rem !important;
}
