.Contactinfo {
  background-color: white;
  width: 100%;
  height: max-content;
  min-height: 50vh;
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  gap: 1.5rem;
}
.Contactinfo .Contactheader {
  font-weight: bold;
}

/* this is used for invalid inputs */
.Contactinfo .redError {
  border: red solid 2px;
}
.Accordionbtn {
  padding-left: 0 !important;
  margin-left: 0 !important;
  font-weight: bold;
}
.Accordioitem {
  border: 0;
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.Accordionpanel {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 1rem !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  padding-right: 0 !important;
}
.Updatebtn {
  background-color: #23a994;
  width: 10rem;
}
