.Plans {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Plans .title {
  font-weight: bolder;
  font-size: 2rem;
  text-shadow: 0px 1px, 1px 0px, 1px 1px;
}
.Plans .form {
  width: 90%;
  display: flex;
}
.Plans .form .leftForm,
.Plans .form .rightForm {
  flex: 2;
  margin: 3rem;
}
.Plans .Label {
  width: 98%;
  margin-top: 2rem;
  margin-bottom: 3px;
  align-items: flex-start;
  font-weight: bold;
  text-align: left;
}
.Plans .btns {
  display: flex;
  flex: 2;
  width: 90%;
  margin-left: 6rem;
  margin-bottom: 6rem;
  align-items: flex-start;
}
.Plans .btns .id1 {
  background: #23a994;
  padding: 1.5rem;
  padding-inline: 2rem;
  margin-right: 2rem;
}

.Submitedcontainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  align-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 7rem;
}
.Submitedcontainer .Submitedheader {
  font-size: 2rem;
  font-weight: bolder;
  margin-bottom: 3rem;
}
.Submitedcontainer .Successcheck {
  margin-top: 3rem;
  width: 5rem !important;
  height: 5rem !important;
}

@media (max-width: 1000px) {
  .Plans {
    flex-direction: column;
    width: 100vw;
  }
  .Plans .form {
    width: 98vw;
  }
  .Plans .form .leftForm,
  .Plans .form .rightForm {
    margin: 0rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .Plans .btns {
    width: fit-content;
    align-self: flex-start;
    margin-left: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
@media (max-width: 760px) {
  .Plans {
    flex-direction: column;
    width: 100vw;
  }
  .Plans .form {
    width: 98%;
    flex-direction: column;
  }
  .Plans .form .leftForm,
  .Plans .form .rightForm {
    margin: 0rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .Plans .btns {
    width: fit-content;
    align-self: flex-start;
    margin-left: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
