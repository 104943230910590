.Homepage {
  display: flex;
  flex-direction: column;
  height: max-content;
  min-height: 100vh;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.Homepage .Topnaviguation {
  position: sticky;
}
.Homepage .Herosection {
  z-index: 2;
}
.Homepage .Categoriessection {
  z-index: 1;
}
.Homepage .Footer {
  height: max-content;
}
