@keyframes jump {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0);
  }
}

.jump-animation {
  animation: jump 0.5s ease-in-out;
}
