.Dashboard {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
}
.Dashboard .Dashboardheader {
  font-weight: bolder;
  font-size: large;
}
.Dashboard .Dashboardsubheader {
  font-size: small;
  margin-top: 0.5rem;
}
.Dashboard .Menucontainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Dashboard .Menucontainer .Menu {
  width: max-content;
  align-self: center;
  align-items: center;
  align-content: center;
}
.Dashboard .Menucontainer .Menubtn {
  background-color: white !important;
}
.Dashboard .Menucontainer .Menuindicator {
  align-self: center;
  font-weight: bold;
}
.Dashboard .Cardscontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  align-content: flex-start;
  align-items: flex-start;
}
.Dashboard .Cardscontainer .Bookingscard {
  background-color: white;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex: 0.3;
  position: relative;
}
.Dashboard .Cardscontainer .Bookingscard .popover {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 5%;
}
.Dashboard .Cardscontainer .Bookingscard .Bookingscardheader {
  font-weight: bold;
  font-size: x-large;
}
.Dashboard .Cardscontainer .Bookingscard .Bookingscardsubheader {
  font-size: small;
  margin-top: 0.5rem;
}
.Dashboard .Cardscontainer .Bookingscard .Numberbookings {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Dashboard .Cardscontainer .Bookingscard .Numberbookings .Number {
  font-weight: bold;
  font-size: 3rem;
  color: #23a994;
  align-self: center;
}
.Dashboard .Cardscontainer .Bookingscard .Numberbookings .Stat {
  align-self: flex-end;
  color: #23a994;
}
.Dashboard .Chartcontainer {
  background-color: white;
  border-radius: 12px;
  margin-top: 1.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.Dashboard .Chartcontainer .Chartheader {
  font-weight: bold;
}
.Dashboard .Chartcontainer .Chart {
  width: 100%;
}
.Dashboard .Piecontainer {
  background-color: white;
  border-radius: 12px;
  margin-top: 1.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Dashboard .Piecontainer .Pieheader {
  font-weight: bold;
}
.BarChartcontainer {
  display: none;
}
@media (max-width: 828px) {
  .Dashboard .Cardscontainer {
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .Dashboard .Cardscontainer .Bookingscard {
    background-color: white;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .Dashboard .Chartcontainer {
    display: none;
  }
  .BarChartcontainer {
    background-color: white;
    border-radius: 12px;
    margin-top: 1.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .BarChartcontainer .Chartheader {
    font-weight: bold;
  }
}

/************************************/
.Progresscontainer {
  display: flex !important;
  width: 20rem;
  gap: 0.5rem;
  flex-direction: row !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
  justify-content: flex-end !important;
  align-self: flex-end !important;
}
.Progress {
  align-self: center !important;
  border-radius: 12px;
}
.Progressnumber {
  min-width: 2rem;
}
progress[value]::-moz-progress-bar {
  background-color: #23a994;
}
progress[value]::-moz-progress-value {
  background-color: #23a994;
}
.stattext {
  font-size: large !important;
}
.statarrow {
  font-size: large !important;
}
