.RegisterClient {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
  }
  .RegisterClient .Left {
    display: flex;
    flex: 0.58;
    flex-direction: column;
  }
  
  .RegisterClient .Left .logo_container {
    margin-left: 1rem;
  }
  .RegisterClient .Left .logo_container .logo {
    min-width: 13rem;
    max-width: 13rem;
    margin-top: 0.5rem;
  }
  .RegisterClient .Left .Header {
    font-weight: 600;
    font-size: xx-large;
    margin-top: 1rem;
    margin-left: 7rem;
  }
  .RegisterClient .Left .Subheader {
    margin-left: 7rem;
    margin-top: 1rem;
    color: #4d4d4d;
  }
  .RegisterClient .Left .Form {
    margin-left: 7rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .RegisterClient .Left .Form .Label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .RegisterClient .Left .Form .Input {
    width: 33rem;
    margin-top: 0.5rem;
  }
  .RegisterClient .Left .Form .inputGroup {
    width: fit-content;
  }
  .RegisterClient .Left .Form .showHide {
    margin-top: 1.5rem;
  }
  .RegisterClient .Left .termsConditions {
    margin-left: 7rem;
    margin-top: 1rem;
    width: 33rem;
  }
  .RegisterClient .Left .termsConditions .Link:hover {
    text-decoration: underline;
  }
  .RegisterClient .Left .termsConditions .Link .Icon {
    margin-bottom: 0.2rem;
  }
  .RegisterClient .Left .btnSubmit {
    margin-left: 7rem;
    margin-top: 1rem;
    width: 33rem;
    background-color: #23a994;
  }
  .RegisterClient .Left .loginText {
    margin-left: 7rem;
    margin-top: 1rem;
    width: 33rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .RegisterClient .Left .loginText .Link {
    cursor: pointer;
    margin-left: 0.3rem;
  }
  .RegisterClient .Left .loginText .Link:hover {
    text-decoration: underline;
  }
  .RegisterClient .Right {
    display: flex;
    flex: 0.42;
    width: 100%;
    max-height: 100vh;
  }
  .RegisterClient .Right .Imagecontainer {
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(
        180deg,
        rgba(36, 169, 148, 0.4) -2.06%,
        #000000 150.42%
      ),
      url("/public/assets/Login/login_right.jpg");
    background-size: cover;
    background-position: 5%;
    display: flex;
    padding-bottom: 10%;
    padding-left: 10%;
    padding-right: 10%;
  }
  .RegisterClient .Right .Imagecontainer .Headerscontainer {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
  }
  .RegisterClient .Right .Imagecontainer .Headerscontainer .Header {
    color: white;
    font-size: 2vw;
  }
  .RegisterClient .Right .Imagecontainer .Headerscontainer .Subheader {
    color: white;
    font-size: 1vw;
  }
  
  @media (max-width: 1400px) {
    .RegisterClient .Left {
      display: flex;
      flex: 0.55;
      flex-direction: column;
    }
    .RegisterClient .Right {
      display: flex;
      flex: 0.45;
      width: 100%;
      max-height: 100vh;
    }
    .RegisterClient .Left .Header {
      font-weight: 600;
      font-size: xx-large;
      margin-top: 1rem;
      margin-left: 4rem;
    }
    .RegisterClient .Left .Subheader {
      margin-left: 4rem;
      margin-top: 1rem;
      color: #4d4d4d;
    }
    .RegisterClient .Left .Form {
      margin-left: 4rem;
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .RegisterClient .Left .Form .Label {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    .RegisterClient .Left .Form .Input {
      width: 28rem;
      margin-top: 0.5rem;
    }
    .RegisterClient .Left .Form .inputGroup {
      width: fit-content;
    }
    .RegisterClient .Left .Form .showHide {
      margin-top: 1.5rem;
    }
    .RegisterClient .Left .termsConditions {
      margin-left: 4rem;
      margin-top: 1rem;
      width: 28rem;
    }
    .RegisterClient .Left .termsConditions .Link:hover {
      text-decoration: underline;
    }
    .RegisterClient .Left .termsConditions .Link .Icon {
      margin-bottom: 0.2rem;
    }
    .RegisterClient .Left .btnSubmit {
      margin-left: 4rem;
      margin-top: 1rem;
      width: 28rem;
      background-color: #23a994;
    }
    .RegisterClient .Left .loginText {
      margin-left: 4rem;
      margin-top: 1rem;
      width: 28rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  @media (max-width: 1100px) {
    .RegisterClient .Left {
      display: flex;
      flex: 0.55;
      flex-direction: column;
    }
    .RegisterClient .Right {
      display: flex;
      flex: 0.45;
      width: 100%;
      max-height: 100vh;
    }
    .RegisterClient .Left .Header {
      font-weight: 600;
      font-size: xx-large;
      margin-top: 1rem;
      margin-left: 2rem;
    }
    .RegisterClient .Left .Subheader {
      margin-left: 2rem;
      margin-top: 1rem;
      color: #4d4d4d;
    }
    .RegisterClient .Left .Form {
      margin-left: 2rem;
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .RegisterClient .Left .Form .Label {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    .RegisterClient .Left .Form .Input {
      width: 28rem;
      margin-top: 0.5rem;
    }
    .RegisterClient .Left .Form .inputGroup {
      width: fit-content;
    }
    .RegisterClient .Left .Form .showHide {
      margin-top: 1.5rem;
    }
    .RegisterClient .Left .termsConditions {
      margin-left: 2rem;
      margin-top: 1rem;
      width: 28rem;
    }
    .RegisterClient .Left .termsConditions .Link:hover {
      text-decoration: underline;
    }
    .RegisterClient .Left .termsConditions .Link .Icon {
      margin-bottom: 0.2rem;
    }
    .RegisterClient .Left .btnSubmit {
      margin-left: 2rem;
      margin-top: 1rem;
      width: 28rem;
      background-color: #23a994;
    }
    .RegisterClient .Left .loginText {
      margin-left: 2rem;
      margin-top: 1rem;
      width: 28rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media (max-width: 940px) {
    .RegisterClient .Left {
      display: flex;
      flex: 0.55;
      flex-direction: column;
    }
    .RegisterClient .Right {
      display: none;
    }
    .RegisterClient .Left .Header {
      font-weight: 600;
      font-size: xx-large;
      margin-top: 1rem;
      margin-left: 4rem;
    }
    .RegisterClient .Left .Subheader {
      margin-left: 4rem;
      margin-top: 1rem;
      color: #4d4d4d;
    }
    .RegisterClient .Left .Form {
      margin-left: 4rem;
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .RegisterClient .Left .Form .Label {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    .RegisterClient .Left .Form .Input {
      width: 28rem;
      margin-top: 0.5rem;
    }
    .RegisterClient .Left .Form .inputGroup {
      width: fit-content;
    }
    .RegisterClient .Left .Form .showHide {
      margin-top: 1.5rem;
    }
    .RegisterClient .Left .termsConditions {
      margin-left: 4rem;
      margin-top: 1rem;
      width: 28rem;
    }
    .RegisterClient .Left .termsConditions .Link:hover {
      text-decoration: underline;
    }
    .RegisterClient .Left .termsConditions .Link .Icon {
      margin-bottom: 0.2rem;
    }
    .RegisterClient .Left .btnSubmit {
      margin-left: 4rem;
      margin-top: 1rem;
      width: 28rem;
      background-color: #23a994;
    }
    .RegisterClient .Left .loginText {
      margin-left: 4rem;
      margin-top: 1rem;
      width: 28rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  @media (max-width: 560px) {
    .RegisterClient .Left {
      display: flex;
      flex: 1;
    }
    .RegisterClient .Right {
      display: none;
    }
    .RegisterClient .Left .Header {
      font-size: x-large;
      margin: auto;
      margin-top: 2rem;
      text-align: center;
    }
    .RegisterClient .Left .Subheader {
      margin: auto;
      text-align: center;
      color: #4d4d4d;
    }
    .RegisterClient .Left .Form {
      width: 99vw;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .RegisterClient .Left .Form .Label {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-left: 0.6rem;
    }
    .RegisterClient .Left .Form .Input {
      width: 95vw;
      margin: auto;
      margin-left: 1%;
    }
  
    .RegisterClient .Left .termsConditions {
      width: 95vw;
      margin: auto;
    }
  
    .RegisterClient .Left .btnSubmit {
      width: 95vw;
      margin: auto;
      background-color: #23a994;
    }
    .RegisterClient .Left .loginText {
      width: 95vw;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .iconVise {
      margin-bottom: 0.7rem;
    }
    .terms_conditions_text {
      font-size: 15px;
    }
  }
  