.Loginright {
  width: 100%;
  max-height: 100vh;
}
.Loginright .Imagecontainer {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
      180deg,
      rgba(36, 169, 148, 0.4) -2.06%,
      #000000 150.42%
    ),
    url("/public/assets/Login/login_right.jpg");
  background-size: cover;
  background-position: 5%;
  display: flex;
  padding-bottom: 10%;
  padding-left: 10%;
  padding-right: 10%;
}
.Loginright .Imagecontainer .Headerscontainer {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}
.Loginright .Imagecontainer .Headerscontainer .Header {
  color: white;
  font-size: 2vw;
}
.Loginright .Imagecontainer .Headerscontainer .Subheader {
  color: white;
  font-size: 1vw;
}
