.BusinesssidebarMobile {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 4rem;
  position: sticky;
  top: 0;
  background-color: white;
}
.BusinesssidebarMobile .Drawercontainer {
  display: flex;
  align-self: center;
  margin-left: 2rem;
}
.BusinesssidebarMobile .Drawercontainer .Drawerbtn {
  width: 3rem;
}
.BusinesssidebarMobile .Logocontainer {
  display: flex;
  align-self: center;
  width: 11rem;
  margin-left: 2rem;
}
.Nav {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
.Nav .Navitem {
  display: flex;
  margin-bottom: 2rem;
  flex-direction: row;
  margin-left: 1rem;
}
.Nav .Navitem .Navicon {
  align-self: center;
  margin-right: 0.5rem;
}
.Drawerfooter {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
}
#Logout {
  margin-left: 1rem;
  display: flex;
}
#Logout .Navicon {
  align-self: center;
  margin-right: 0.5rem;
}
.Settingsaccord {
  border: 0 !important;
  margin: 0;
  padding: 0;
}
.Box {
  display: flex;
  flex-direction: row;
}
.SettingsNavicon {
  align-self: center;
  margin-right: 0.5rem;
}
.Settingstxt {
  align-self: center;
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: large;
}

/***************************Settings list css*************************************/
.Settingslist {
  display: flex;
  flex-direction: column;
  margin-left: 1.8rem;
  margin-top: 1rem;
  border-left: 1px solid rgba(63, 54, 54, 0.282) !important;
}
.Settingslist .Listitem {
  margin-bottom: 1rem;
}
.Settingslist .Listitem:hover {
  font-weight: bold;
}
