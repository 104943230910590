.Businesssidebar {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  max-height: fit-content;
  width: 100%;
}
.Businesssidebar .Logocontainer {
  max-width: 11rem;
  min-width: 11rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.Businesssidebar .Nav {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}
.Businesssidebar .Nav .Navitem {
  margin-left: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  position: relative;
}

.Businesssidebar .Nav .Navitem .soon_tag:hover {
  font-weight: 200;
}
.Businesssidebar .Nav .Navitem:hover {
  font-weight: bold;
}
.Businesssidebar .Nav .Navitem .Navicon {
  align-self: center;
  margin-right: 0.5rem;
}
.Businesssidebar .Nav .Navitem .soon_tag {
  position: absolute;
  right: 8px;
  top: 5px;
  font-size: 0.8rem;
  color: rgb(65, 65, 65);
  background: rgb(235, 235, 235);
  padding: 0px 5px 0px 5px;
  border-radius: 4px;
}

#Logout {
  margin-top: 3rem !important;
  cursor: pointer;
  color: #fe6b28;
}

.isDisabled {
  pointer-events: none;
  color: #888;
  text-decoration: line-through;
}
