.Loginleft {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.Loginleft .Logocontainer {
  min-width: 13rem;
  max-width: 13rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.Loginleft .Header {
  margin-left: 6.5rem;
  font-weight: 600;
  font-size: xx-large;
  margin-top: 4rem;
}
.Loginleft .Subheader {
  margin-left: 6.5rem;
  margin-top: 2rem;
  color: #4d4d4d;
}
.Loginleft .Label {
  margin-left: 6.5rem;
  margin-top: 4rem;
}
.Loginleft .Emailinput {
  width: 30rem;
  margin-left: 6.5rem;
  margin-top: 1rem;
}
.Loginleft .Passlabel {
  margin-left: 6.5rem;
  margin-top: 2rem;
}
.Loginleft .Passinput {
  width: 30rem;
  margin-left: 6.5rem;
  margin-top: 1rem;
}
.Loginleft .Submitbtn {
  width: 30rem;
  margin-left: 6.5rem;
  margin-top: 3rem;
  background-color: #23a994;
}
.Loginleft .Fergotpass {
  width: 20rem;
  height: 1rem;
  margin-top: 1rem;
  margin-left: 6.5rem;
  text-decoration: underline;
}
.Password {
  width: 30rem;
  height: 3rem !important;
}
.ShowHide {
  margin-top: 0.5rem;
}

@media (max-width: 940px) {
  .Loginleft .Logocontainer {
    margin-left: 2rem;
  }
  .Loginleft .Header {
    font-size: x-large;
    margin-left: 4.5rem;
  }
  .Loginleft .Subheader {
    margin-left: 4.5rem;
  }
  .Loginleft .Label {
    margin-left: 4.5rem;
  }
  .Loginleft .Emailinput {
    width: 23rem;
    margin-left: 4.5rem;
  }
  .Loginleft .Passlabel {
    margin-left: 4.5rem;
  }
  .Loginleft .Passinput {
    width: 23rem;
    margin-left: 4.5rem;
  }
  .Loginleft .Fergotpass {
    margin-left: 4.5rem;
    width: 10rem;
  }
  .Loginleft .Submitbtn {
    width: 23rem;
    margin-left: 4.5rem;
  }
}
