.checkout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  gap: 1rem;
  font-family: "Poppins", sans-serif;
}

.checkout .toppart {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 7rem;
  margin-left: 3rem;
  margin-right: 3rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f7f7fa;
  align-items: center;
}

.checkout .toppart .Previous {
  display: flex;
  width: max-content;
  height: max-content;
}

.checkout .toppart .Previous .Previousbtn {
  border-radius: 50%;
}

.checkout .toppart .Previous .Previousbtn .Previousicon {
  width: 1.5rem;
  height: 1.5rem;
}

.checkout .toppart .Header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
}

.checkout .toppart .Header .Topheader {
  font-size: larger;
  font-weight: bold;
}

.checkout .toppart .Header .Subheader {
  font-size: medium;
  font-weight: bold;
  color: gray;
}

.checkout .Bottom_Part {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-bottom: 10rem;
}

.checkout .Bottom_Part .Stepper {
  width: 22%;
  align-self: center;
}

.checkout .Bottom_Part .Bottom_Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: max-content;
}

.checkout .Bottom_Part .Bottom_Container .Left_Container {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  gap: 1rem;
  margin-left: 7rem;
}

.checkout .Bottom_Part .Bottom_Container .Left_Container .Header {
  font-size: larger;
  font-weight: 700;
}

.checkout .Bottom_Part .Bottom_Container .Left_Container .Submit_Button {
  background-color: #23a994;
  font-weight: 600;
}

.checkout .Bottom_Part .Bottom_Container .Left_Container .Terms_Conditions {
  font-size: small;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
}

.checkout
  .Bottom_Part
  .Bottom_Container
  .Left_Container
  .Terms_Conditions
  .Link {
  cursor: pointer;
  text-decoration: underline;
}

.checkout .Bottom_Part .Bottom_Container .Right_Container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 12px;
  margin-right: 7rem;
  border: 2px solid #f7f7fa;
  flex: 0.5;
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.5);
}

.checkout .Bottom_Part .Bottom_Container .Right_Container .Head_Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  border-bottom: 2px solid #f7f7fa;
  padding-bottom: 1rem;
}

.checkout
  .Bottom_Part
  .Bottom_Container
  .Right_Container
  .Head_Container
  .Head_Info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.checkout
  .Bottom_Part
  .Bottom_Container
  .Right_Container
  .Head_Container
  .Head_Info
  .Saloon_Name {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  font-size: larger;
  font-weight: 700;
}

.checkout
  .Bottom_Part
  .Bottom_Container
  .Right_Container
  .Head_Container
  .Head_Info
  .Saloon_Location {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-size: larger;
  font-weight: bold;
  color: #23a994;
}

.checkout .Bottom_Part .Bottom_Container .Right_Container .Contact_Container {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #f7f7fa;
  padding-bottom: 2rem;
}

.checkout
  .Bottom_Part
  .Bottom_Container
  .Right_Container
  .Contact_Container
  .Header {
  font-size: larger;
  font-weight: 600;
}

.checkout
  .Bottom_Part
  .Bottom_Container
  .Right_Container
  .Contact_Container
  .Item {
  display: flex;
  align-items: center;
  flex: row;
  font-size: medium;
  gap: 0.5rem;
  font-weight: bold;
  margin-top: 1rem;
}

.checkout
  .Bottom_Part
  .Bottom_Container
  .Right_Container
  .Contact_Container
  .Item
  .Icon {
  color: #23a994;
}

.checkout
  .Bottom_Part
  .Bottom_Container
  .Right_Container
  .Contact_Container
  .Item
  .Sub_Text {
  font-size: medium;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .checkout .Bottom_Part .Bottom_Container .Left_Container {
    display: flex;
    flex: 0.55;
    flex-direction: column;
    gap: 1rem;
    margin-left: 0.5rem;
  }

  .checkout .Bottom_Part .Bottom_Container .Right_Container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 12px;
    margin-right: 0.5rem;
    border: 2px solid #f7f7fa;
    flex: 0.45;
    box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.5);
  }
}

@media (max-width: 1000px) {
  .checkout .Bottom_Part .Bottom_Container {
    display: flex;
    flex-direction: column;
  }

  .checkout .Bottom_Part .Bottom_Container .Left_Container {
    display: flex;
    /* flex: 0.55; */
    flex-direction: column;
    gap: 1rem;
    margin-left: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  .checkout .Bottom_Part .Bottom_Container .Left_Container input,
  .checkout .Bottom_Part .Bottom_Container .Left_Container textarea,
  .checkout .Bottom_Part .Bottom_Container .Left_Container button {
    width: 100%;
  }

  .checkout .Bottom_Part .Bottom_Container .Right_Container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 12px;
    margin-right: 0.5rem;
    border: 2px solid #f7f7fa;
    /* flex: 0.45; */
    box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.5);
  }
}

@media (max-width: 700px) {
  .checkout .Bottom_Part {
    padding: 1rem;
  }

  .checkout .Bottom_Part .Bottom_Container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .checkout .Bottom_Part .Bottom_Container .Left_Container {
    display: flex;
    flex: 0.55;
    flex-direction: column;
    gap: 0;
    margin-left: 0rem;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .checkout .Bottom_Part .Bottom_Container .Left_Container input,
  .checkout .Bottom_Part .Bottom_Container .Left_Container textarea,
  .checkout .Bottom_Part .Bottom_Container .Left_Container button {
    width: 100%;
    margin-bottom: 0.8rem;
  }

  .checkout .Bottom_Part .Bottom_Container .Right_Container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 1rem;
    border-radius: 12px;
    margin-right: 0rem;
    border: 2px solid #f7f7fa;
    flex: 0.45;
    box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.5);
    width: 100%;
  }

  .checkout .Bottom_Part .Bottom_Container .Right_Container .Head_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border-bottom: 2px solid #f7f7fa;
    padding-bottom: 1rem;
  }

  .checkout
    .Bottom_Part
    .Bottom_Container
    .Right_Container
    .Head_Container
    .Saloon_Location {
    /* text-align: center; */
    font-size: medium;
  }
  .checkout .Bottom_Part .Bottom_Container .Left_Container .Terms_Conditions {
    font-size: small;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
  }

  .checkout
    .Bottom_Part
    .Bottom_Container
    .Left_Container
    .Terms_Conditions
    .Link {
    cursor: pointer;
    text-decoration: underline;
  }
  .checkout .Bottom_Part .Bottom_Container .Left_Container .Header {
    font-size: larger;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  .betaling {
    font-size: small;
  }
}
