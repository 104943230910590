.SalonPage {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
}
.SalonPage .Loading_animation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.SalonPage .Loading_animation .Loading_logo {
  width: 15rem;
  height: 17rem;
}

.SalonPage .header {
  width: 85vw;
  display: flex;
  margin-top: 6rem;
}

.SalonPage .header .left {
  flex: 0.42;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.SalonPage .header .left .secondFlex {
  display: flex;
  flex-direction: column;
}

.SalonPage .header .left .thirdFlex {
  color: #23a994;
  font-size: large;
  font-weight: bold;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.SalonPage .header .left .thirdFlex .locationIcon {
  align-self: center;
}
.SalonPage .header .left .salonName {
  font-weight: bolder;
  font-size: xx-large;
}
.SalonPage .header .left .saloonRating {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.SalonPage .header .left .saloonRating .stars {
  align-self: center;
}
.SalonPage .header .left .saloonRating .rating {
  font-size: large;
  font-weight: bold;
  align-self: flex-end;
}
.SalonPage .header .right {
  flex: 0.58;
  font-size: large;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  padding-right: 4rem;
}

.SalonPage .header .right .elements {
  font-weight: bold;
}
.SalonPage .header .right .elements .breadCrumb {
  color: #23a994;
  font-weight: bold;
}
.SalonPage .header .right .operations {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-weight: bold;
  text-decoration: underline 2px #23a994;
  cursor: pointer;
}

.SalonPage .header .right .operations .delete {
  margin-right: 0.8rem;
  font-weight: bold;
}

.SalonPage .line {
  margin-top: 2rem;
  margin-left: 7%;
  width: 81vw;
  align-self: flex-start;
  border-top: 2px solid grey;
}

.SalonPage .main {
  display: flex;
  justify-content: center;
}

.SalonPage .main .left {
  margin-left: 2%;
  flex: 0.42;
}
.SalonPage .main .right {
  flex: 0.58;
}
.SalonPage .cards .card .Staffinfo {
  margin-left: 4%;
}

.SalonPage .cards .card .Staffname {
  font-weight: bold;
  font-size: 1.1rem;
}

.SalonPage .cards .card {
  display: flex;
  align-items: center;
  padding: 0.4rem;
  border: solid lightgray 1px;
  border-radius: 0.5rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.SalonPage .SaloonLogo {
  width: 120px;
  height: 120px;
  margin-bottom: 1rem;
}
.SalonPage .SaloonLogo .Logo {
  border-radius: 12px;
  width: 120px;
  height: 120px;
  object-fit: contain;
  box-shadow: 5px 5px 13px -3px rgba(0, 0, 0, 0.5);
}
.SalonPage .review {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  border-radius: 0.5rem;
  background-color: #f7f7fa;
  height: fit-content;
}
.SalonPage .review .reviewHeader {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.SalonPage .allReviews {
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 35%;
}

.SalonPage .nameReviewer {
  font-weight: bold;
}

.SalonPage .dateReview {
  font-size: 0.9rem;
  font-weight: bold;
  color: gray;
  margin-bottom: 0.3rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.SalonPage .main .left .description .text {
  font-weight: bold;
  text-justify: distribute;
}

.SalonPage .main .left .description,
.SalonPage .main .left .services,
.SalonPage .main .left .staffs,
.SalonPage .main .left .reviews {
  margin-top: 2rem;
  padding-bottom: 2rem;
  margin-left: 3rem;
  border-bottom: 1px solid lightgray;
}

.SalonPage .main .left h2 {
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.SalonPage .btn_bestil {
  border: solid 2px #23a994;
  background: transparent;
}

.SalonPage .Heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: bold;
}
.SalonPage .Heading .subInfo {
  color: gray;
}
.SalonPage .mere_info,
.SalonPage .less_info {
  font-weight: bold;
  color: #23a994;
}

.SalonPage .Panelheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SalonPage .main .left .Servicelistcontainer .Accordion {
  border: transparent solid 1px;
}
.SalonPage .main .left .Servicelistcontainer .Accordion .serviceItem {
  background-color: #f7f7fa;
}
.SalonPage .main .left .Servicelistcontainer .Accordionheader {
  font-weight: bold;
  font-size: 1.1rem;
}

.SalonPage .main .left .Servicelistcontainer .iconAccordio {
  background: #23a994;
  border-radius: 50%;
}
.SalonPage .main .left .Staffocupation {
  color: #23a994;
  font-weight: bold;
}
.SalonPage .main .right {
  padding: 3rem;
  flex: 0.54;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.SalonPage .test {
  flex: 0.85;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: flex-start;
}
.SalonPage .subimages {
  margin-top: 30px;
  margin-left: 4.5rem;
  margin-bottom: 1.5rem;
}

.SalonPage .subimages img {
  display: inline;
  height: 4rem;
  width: 5.5rem;
  margin-top: 25px;
  margin-right: 40px;
}

.SalonPage .map {
  font-weight: 600;
  font-size: large;
  margin-top: 1.2rem;
  width: 80%;
  margin: auto;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.SalonPage .googlemap {
  background: gray;
  border-radius: 15px;
  width: 99%;
  height: 400px;
  margin: auto;
  margin-top: 1.5rem;
}

.SalonPage .btn_get_direction {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  border: solid 2px #23a994;
  background: transparent;
  padding: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-radius: 10px;
  font-weight: 600;
}
.SalonPage .btn_get_direction:hover {
  background-color: #eaf7f5;
}
.SalonPage .right .salonInfos {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.SalonPage .right .salonInfos .title {
  font-weight: 600;
  font-size: x-large;
}
.SalonPage .right .map .title {
  font-weight: 600;
  font-size: x-large;
}

.SalonPage .right .salonInfos .day {
  font-weight: bold;
  font-size: normal;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.SalonPage .right .salonInfos .contact {
  display: flex;
  font-weight: bold;
  align-items: center;
  margin-top: 0.6rem;
}

.SalonPage .right .salonInfos .links {
  display: flex;
  align-items: center;
  margin-top: 1.3rem;
}

.SalonPage .right .salonInfos .link {
  color: white;
  width: 25px;
  height: 25px;
  background: black;
  border-radius: 50%;
  padding: 0.3rem;
  margin-right: 8px;
}

.SalonPage .space {
  display: inline-block;
  width: 30px;
}

.SalonPage .boldy {
  font-weight: bold;
  color: #23a994;
}

.SalonPage .action {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
#disabled {
  cursor: no-drop !important;
}

.SalonPage .hidden {
  display: none;
}

.SalonPage .Mapcontainer {
  width: 100%;
  height: 100%;
}

.SalonPage .images_mobile {
  display: none;
}

.SalonPage .elements_mobile,
.SalonPage .operations_mobile {
  display: none;
}
.review_in_all {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  border-radius: 0.5rem;
  background-color: #f7f7fa;
  height: fit-content;
}
.review_in_all .modalReviewHeader {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.test_in_all {
  flex: 0.85;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: flex-start;
}
.nameReviewer_in_all {
  font-weight: bold;
}
.Modalheader {
  font-weight: 700 !important;
  font-size: x-large !important;
}
.Subservice_Description {
  font-size: medium;
  font-weight: bold;
  text-justify: distribute;
  margin-bottom: 1rem;
}
.dateReview_in_all {
  font-size: 0.9rem;
  font-weight: bold;
  color: gray;
  margin-bottom: 0.3rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

@media (max-width: 1200px) {
  .SalonPage .header {
    flex-direction: column;
  }

  .SalonPage .main {
    flex-direction: column;
    margin: 0%;
    padding: 0;
    width: 100vw;
  }

  .SalonPage .images_web {
    display: none;
  }

  .SalonPage .images_mobile {
    display: block;
  }

  .SalonPage .elements {
    display: none;
  }

  .SalonPage .elements_mobile {
    display: block;
    margin-bottom: 1rem;
  }

  .line {
    display: none;
  }

  .SalonPage .operations {
    display: none;
    opacity: 0;
  }

  .SalonPage .operations_mobile {
    display: block;
    width: fit-content;
    margin: auto;
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: underline 2px black;
  }

  .SalonPage .operations_mobile .delete {
    margin-right: 1rem;
  }
  .SalonPage .salonInfos {
    flex-direction: column;
    margin-top: 0;
    width: 95%;
    margin: auto;
  }
  .SalonPage .title {
    margin-top: 0.5rem;
  }
  .SalonPage .map {
    font-weight: 600;
    font-size: large;
    margin-top: 1.2rem;
    width: 95%;
    margin: auto;
  }

  .SalonPage .googlemap {
    background: gray;
    border-radius: 15px;
    width: 99%;
    height: 300px;
    margin: auto;
    margin-top: 1.5rem;
  }

  .SalonPage .main .left {
    margin: 0;
  }
  .SalonPage .main .left .description,
  .SalonPage .main .left .services,
  .SalonPage .main .left .staffs,
  .SalonPage .main .left .reviews {
    padding: 0;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    margin: 0%;
    width: 95%;
    margin: auto;
  }
  .SalonPage .main .right {
    padding: 0.4em;
    display: flex;
    flex-direction: column;
  }
  .SalonPage .subimages {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .SalonPage .subimages img {
    width: 90%;
    height: 300px;
    margin: auto;
  }
  .SalonPage .slider-image {
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.5s;
    margin: 6px;
  }

  .SalonPage .image-slider {
    position: relative;
  }

  .SalonPage .subimages {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SalonPage .slider-image {
    max-width: 100%;
    max-height: 300px;
    border-radius: 8px;
    object-fit: cover;
  }

  .SalonPage .navigation {
    display: flex;
    justify-content: center;
    /* margin-top: 16px; */
    position: relative;
    top: -16px;
    z-index: 50;
  }

  .SalonPage .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: gray;
    margin: 0 8px;
    cursor: pointer;
  }

  .SalonPage .dot.active {
    background-color: white;
  }
}

@media (max-width: 940px) {
  .SalonPage .header {
    flex-direction: column;
  }

  .SalonPage .main {
    flex-direction: column;
    margin: 0%;
    padding: 0;
    width: 100vw;
  }

  .SalonPage .images_web {
    display: none;
  }

  .SalonPage .images_mobile {
    display: block;
  }

  .SalonPage .elements {
    display: none;
  }

  .SalonPage .elements_mobile {
    display: block;
    margin-bottom: 1rem;
  }

  .line {
    display: none;
  }

  .SalonPage .operations {
    display: none;
    opacity: 0;
  }

  .SalonPage .operations_mobile {
    display: block;
    width: fit-content;
    margin: auto;
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: underline 2px black;
  }

  .SalonPage .operations_mobile .delete {
    margin-right: 1rem;
  }
  .SalonPage .salonInfos {
    flex-direction: column;
    margin-top: 0;
    width: 95vw;
    margin: auto;
  }
  .SalonPage .title {
    margin-top: 0.5rem;
  }
  .SalonPage .map {
    font-weight: 600;
    font-size: large;
    margin-top: 1.2rem;
    width: 95%;
    margin: auto;
  }

  .SalonPage .googlemap {
    background: gray;
    border-radius: 15px;
    width: 99%;
    height: 300px;
    margin: auto;
    margin-top: 1.5rem;
  }

  .SalonPage .main .left {
    margin: 0;
  }
  .SalonPage .main .left .description,
  .SalonPage .main .left .services,
  .SalonPage .main .left .staffs,
  .SalonPage .main .left .reviews {
    padding: 0;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    margin: 0%;
    width: 95vw;
    margin: auto;
  }
  .SalonPage .main .right {
    padding: 0.4em;
    display: flex;
    flex-direction: column;
  }
  .SalonPage .subimages {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .SalonPage .subimages img {
    width: 90vw;
    height: 300px;
    margin: auto;
  }
  .SalonPage .slider-image {
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.5s;
    margin: 6px;
  }

  .SalonPage .image-slider {
    position: relative;
  }

  .SalonPage .subimages {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SalonPage .slider-image {
    max-width: 100%;
    max-height: 300px;
    border-radius: 8px;
    object-fit: cover;
  }

  .SalonPage .navigation {
    display: flex;
    justify-content: center;
    /* margin-top: 16px; */
    position: relative;
    top: -16px;
    z-index: 50;
  }

  .SalonPage .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: gray;
    margin: 0 8px;
    cursor: pointer;
  }

  .SalonPage .dot.active {
    background-color: white;
  }
}
