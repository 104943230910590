@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
.Upgrade {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 110vh;
  height: max-content;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
}
.Upgrade .Header {
  font-weight: bold;
  font-size: 3.5rem;
  text-align: center;
  margin-top: 3rem;
}
.Upgrade .Subheader {
  font-size: 1.5rem;
  margin-top: 1rem;
  text-align: center;
  color: gray;
}
.Upgrade .Upgradebtn {
  text-align: center;
  margin-top: 1rem;
}
.Upgrade .Upgradebtn .Upgradebtnbtn {
  background-color: #23a994;
}
.Upgrade .Upgradeplans {
  display: flex;
  flex: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 3rem;
}
.Upgrade .Upgradeplans .Starterplan {
  display: flex;
  flex-direction: column;
  min-width: 28rem;
  background: rgb(8, 87, 75);
  background: linear-gradient(
    170deg,
    rgba(8, 87, 75, 1) 0%,
    rgba(35, 169, 148, 1) 35%,
    rgba(35, 169, 148, 1) 100%
  );
  min-height: 80vh;
  border-radius: 12px;
  padding: 2rem;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
.Upgrade .Upgradeplans .Starterplan .plan_header {
  color: white;
  font-size: x-large;
  font-weight: bold;
}
.Upgrade .Upgradeplans .Starterplan .plan_pricing {
  color: white;
  font-size: 4rem;
  font-weight: bolder;
}
.Upgrade .Upgradeplans .Starterplan .features {
  display: flex;
  flex-direction: column;
}
.Upgrade .Upgradeplans .Starterplan .features .features_header {
  color: white;
  font-weight: bold;
}
.Upgrade .Upgradeplans .Starterplan .features .features_subheader {
  color: white;
  font-weight: normal;
}
.Upgrade .Upgradeplans .Starterplan .plan_points {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
}
.Upgrade .Upgradeplans .Starterplan .plan_points .point {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  font-size: large;
  font-weight: 600;
}
.Upgrade .Upgradeplans .Premiumplan {
  display: flex;
  flex-direction: column;
  min-width: 28rem;
  background: rgb(147, 55, 12);
  background: linear-gradient(
    170deg,
    rgba(147, 55, 12, 1) 0%,
    rgba(254, 107, 40, 1) 35%,
    rgba(216, 126, 84, 1) 100%
  );
  min-height: 80vh;
  border-radius: 12px;
  padding: 2rem;
  gap: 0.5rem;
  margin-bottom: 2rem;
}
.Upgrade .Upgradeplans .Premiumplan .plan_header {
  color: white;
  font-size: x-large;
  font-weight: bold;
}
.Upgrade .Upgradeplans .Premiumplan .plan_pricing {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: white;
  align-items: center;
}
.Upgrade .Upgradeplans .Premiumplan .plan_pricing .price {
  font-size: 4rem;
  font-weight: bolder;
}
.Upgrade .Upgradeplans .Premiumplan .plan_pricing .additional_info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.Upgrade .Upgradeplans .Premiumplan .features {
  display: flex;
  flex-direction: column;
}
.Upgrade .Upgradeplans .Premiumplan .features .features_header {
  color: white;
  font-weight: bold;
}
.Upgrade .Upgradeplans .Premiumplan .features .features_subheader {
  color: white;
  font-weight: normal;
}
.Upgrade .Upgradeplans .Premiumplan .plan_points {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
}
.Upgrade .Upgradeplans .Premiumplan .plan_points .point {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  font-size: large;
  font-weight: 600;
}
