.Business {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
}
.Business .BusinesssidebarMobile {
  display: none;
}
.Business .Businesssidebar {
  flex: 0.15;
  display: flex;
  position: sticky;
  position: -webkit-sticky; /* add the -webkit-sticky property for older versions of Safari */
  top: 0;
}
.Business .Businessright {
  display: flex;
  flex: 0.85;
  height: max-content;
}
@media (max-width: 1440px) {
  .Business .Businesssidebar {
    min-width: max-content;
    padding-right: 0.5rem;
  }
}
@media (max-width: 1370px) {
  .Business {
    flex-direction: column;
  }
  .Business .Businesssidebar {
    display: none;
  }
  .Business .BusinesssidebarMobile {
    display: flex;
    flex-direction: row;
    flex: 0.1;
    top: 0;
    align-self: flex-start;
    z-index: 20;
  }
  .Business .Businessright {
    flex: 0.9;
    z-index: 0;
  }
}
