.Login {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}
.Login .Loginleft {
  display: flex;
  flex: 0.6;
}
.Login .Loginright {
  display: flex;
  flex: 0.4;
}
@media (max-width: 640px) {
  .Login .Loginright {
    display: none;
  }
}
