.Appointments {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}
.Appointments .Header {
  font-weight: bolder;
  font-size: large;
}
.Appointments .Subheadercontainer {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  justify-content: space-between;
}
.Appointments .Subheadercontainer .Subheader {
  font-size: medium;
  font-weight: bold;
}
.Refreshbtn {
  color: black;
  background-color: #23a994 !important;
}
.Appointments .Tablecontainer {
  margin-top: 2rem;
  background-color: white;
  border-radius: 12px;
}
.Appointments .Tablecontainer .Table {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: black !important;
}
.Acceptlink {
  text-decoration: underline;
  color: #23a994;
  margin-right: 0.5rem;
}
.Declinelink {
  text-decoration: underline;
  color: red;
}
.Td {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Notes {
  display: flex;
  flex-direction: column;
  align-self: center !important;
  align-items: center !important;
  align-content: center !important;
}
.Noteicon {
  cursor: pointer;
}
