.search_page_input .Inputgroup {
  position: relative;
}

.search_page_input .Inputgroup .Input {
  background-color: white;
  border-radius: 25px;
  height: 3.5rem;
  color: black;
  font-size: x-large;
}

.search_page_input .Inputgroup .Inputright {
  display: flex;
  flex-direction: column;
  height: 3.5rem;
}

.search_page_input .Inputgroup .Inputright .Searchbtn {
  background-color: #23a994;
  border-radius: 25px;
}

.search_page_input .Dropdown {
  position: absolute;
  top: 13rem;
  z-index: 9;
  width: 40rem;
  /*max-height: 15rem;*/
  max-height: fit-content;
  /*overflow-y: scroll;*/
  background-color: white;
  display: flex;
  border: 0.2px solid rgba(128, 128, 128, 0.121);
  flex-direction: column;
  border-radius: 12px;
  color: black;
  align-items: flex-start;
  /*padding-top: 1rem;*/
  padding-top: 0.3rem;
  padding-bottom: 1rem;
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
}

.search_page_input .Dropdown::-webkit-scrollbar {
  border-radius: 12px;
  background-color: transparent;
  width: 10px;
  margin-right: 3px;
}

.search_page_input .Dropdown::-webkit-scrollbar-thumb {
  border-radius: 12px;
  width: 10px;
  background-color: lightgray;
  margin-right: 3px;
}

.search_page_input .Dropdown:empty {
  border: none;
  display: none;
}

.search_page_input .Dropdown .Dropdownrow {
  display: flex;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  min-height: 3rem;
  border-bottom: 0.5px solid rgba(211, 211, 211, 0.471);
}

.search_page_input .Dropdown .Dropdownrow:hover {
  background-color: #e8f6f4;
}

.search_page_input .Dropdown .Dropdownrow .Dropdowntext {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  gap: 1rem;
  align-items: center;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 600px;
  /* Adjust as needed */
}

.search_page_input .Dropdown .Dropdownrow .Dropdowntext .Avatar {
  align-self: center;
  margin-left: 10px;
}

.search_page_input .Dropdown .Dropdownrow .Dropdownicon {
  font-size: 0.9rem;
  font-weight: 400;
  color: gray;
  align-self: center;
  margin-right: 1rem;
  margin-left: 1rem;
}

.search_page_input .Dropdown .Dropdownrow .selectIcon {
  font-size: 0.9rem;
  font-weight: 400;
  color: gray;
  align-self: center;
  position: absolute;
  right: 10px;
}

.filter_flex .Select {
  font-weight: bold;
  width: clamp(3rem, 40%, 16rem);
  border-radius: 12px !important;
}

.newSalon {
  font-weight: 500;
}
.salon_box .Saloon {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
  min-height: 7rem;
  border-radius: 12px;
  cursor: pointer;
}

.salon_box .Saloon .Logo .logoImage {
  width: 25rem;
  height: 13rem;
  object-fit: cover;
  border-radius: 12px;
}

.salon_box .Saloon .saloonInfo {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;
}

.salon_box .Saloon .saloonInfo .saloonName {
  font-size: larger;
  font-weight: bold;
}

.salon_box .Saloon .saloonInfo .saloonAdress {
  font-size: medium;
  text-justify: distribute;
  color: gray;
  margin-top: 0.5rem;
}

.salon_box .Saloon .saloonInfo .saloonFooter {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 1rem;
}

.SearchTest
  .saloonSection
  .saloonsList
  .Saloon
  .saloonInfo
  .saloonFooter
  .saloonRating {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
  font-size: large;
}

.SearchTest
  .saloonSection
  .saloonsList
  .Saloon
  .saloonInfo
  .saloonFooter
  .saloonRating
  .ratingStars {
  margin-bottom: 0.3rem !important;
}

.map_marker {
  width: 100px;
  height: 100px;
  background-color: red !important;
  z-index: 100;
}

.mapSide {
  width: 70vw;
  height: calc(100vh-90px);
  position: fixed;
  right: 0;
  top: 90px;
  z-index: 50;
}

.mapSideBlur {
  width: 70%;
  height: calc(100vh - 90px);
  position: fixed;
  right: 0;
  top: 90px;
  z-index: 50;
  filter: brightness(60%);
}

.cardsSide {
  width: 29%;
  position: absolute;
  left: 0;
}
.doBlur {
  width: 30vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  filter: brightness(60%);
  top: 90px;
  height: calc(100vh - 90px);
  z-index: 50;
  background: #e8f6f4;
  opacity: 30%;
}
.doHide {
  display: none;
}
.cardsSidetop {
  position: fixed;
  top: 75px;
  width: 29%;
  height: 10vh;
  background: white;
  z-index: 30;
}

.cardsSection {
  position: absolute;
  top: calc(11vh);
  display: flex;
  flex-direction: column;
}

.cardsSidetop .Searchcontainer {
  position: absolute;
  top: -57px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  right: 0;
  z-index: 60;
  margin-left: auto;
  margin-right: auto;
  width: 70vw;
}

.cardsSidetop .Searchcontainer .Inputgroup {
  position: relative;
  /* background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 5px solid rgba(156, 118, 118, 0.3); */
  width: 40vw;
  /* z-index: 90; */

  margin: auto;
}

.cardsSidetop .Searchcontainer .Inputgroup .Input {
  background-color: white;
  border-radius: 25px;
  height: 3.5rem;
  color: black;
  font-size: large;
}

.cardsSidetop .Searchcontainer .Inputgroup .Inputright {
  display: flex;
  flex-direction: column;
  height: 3.5rem;
}

.cardsSidetop .Searchcontainer .Inputgroup .Inputright .Searchbtn {
  background-color: #23a994;
  width: 90px;
  border-radius: 25px;
}

.cardsSidetop .Searchcontainer .Dropdown {
  position: absolute;
  margin-top: 285px;
  width: 38vw;
  /*max-height: 15rem;*/
  max-height: fit-content;
  /*overflow-y: scroll;*/
  background-color: white;
  display: flex;
  border: 0.2px solid rgba(128, 128, 128, 0.121);
  flex-direction: column;
  border-radius: 12px;
  color: black;
  align-items: flex-start;
  /*padding-top: 1rem;*/
  padding-top: 0.3rem;
  padding-bottom: 1rem;
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.cardsSidetop .Searchcontainer .Dropdown::-webkit-scrollbar {
  border-radius: 12px;
  background-color: transparent;
  width: 10px;
  margin-right: 3px;
}

.cardsSidetop .Searchcontainer .Dropdown::-webkit-scrollbar-thumb {
  border-radius: 12px;
  width: 10px;
  background-color: lightgray;
  margin-right: 3px;
}

.cardsSidetop .Searchcontainer .Dropdown:empty {
  border: none;
  display: none;
}

.cardsSidetop .Searchcontainer .Dropdown .Dropdownrow {
  display: flex;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  min-height: 3rem;
  border-bottom: 0.5px solid rgba(211, 211, 211, 0.471);
}

.cardsSidetop .Searchcontainer .Dropdown .Dropdownrow:hover {
  background-color: #e8f6f4;
}

.cardsSidetop .Searchcontainer .Dropdown .Dropdownrow .Dropdowntext {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
  gap: 1rem;
  align-items: center;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 600px;
  /* Adjust as needed */
}

.cardsSidetop .Searchcontainer .Dropdown .Dropdownrow .Dropdowntext .Avatar {
  align-self: center;
  margin-left: 10px;
}

.Searchcontainer .Dropdown .Dropdownrow .Dropdownicon {
  font-size: 0.9rem;
  font-weight: 400;
  color: gray;
  align-self: center;
  margin-right: 1rem;
  margin-left: 1rem;
}

.cardsSidetop .Searchcontainer .Dropdown .Dropdownrow .selectIcon {
  font-size: 0.9rem;
  font-weight: 400;
  color: gray;
  align-self: center;
  position: absolute;
  right: 10px;
}
@media (max-width: 2200px) and (min-width: 1800px) {
  .mapSide {
    width: 75vw;
    height: calc(100vh-90px);
    position: fixed;
    right: 0;
    top: 90px;
    z-index: 50;
  }

  .mapSideBlur {
    width: 75%;
    height: calc(100vh - 90px);
    position: fixed;
    right: 0;
    top: 90px;
    z-index: 50;
    filter: brightness(60%);
  }
  .doBlur {
    width: 25vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    filter: brightness(60%);
    top: 90px;
    height: calc(100vh - 90px);
    z-index: 50;
    background: #e8f6f4;
    opacity: 30%;
  }
  .cardsSide {
    width: 25%;
    position: absolute;
    left: 0;
  }
  .cardsSidetop {
    position: fixed;
    top: 75px;
    width: 25%;
    height: 10vh;
    background: white;
    z-index: 30;
  }
  .salon_card {
    width: 28rem !important;
  }
}
@media (max-width: 1800px) and (min-width: 1600px) {
  .salon_card {
    width: 30rem !important;
  }
}
@media (max-width: 1260px) {
  .doBlur {
    display: none;
  }
  .search_page_input .Dropdown {
    width: 25rem;
  }

  .salon_box .Saloon .Logo {
    width: 7rem;
    height: 5rem;
    border-radius: 12px;
  }

  .salon_box .Saloon .Logo .logoImage {
    width: 7rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 12px;
  }

  .salon_box .Saloon {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: flex-start;
  }

  .salon_box .Saloon .saloonInfo {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
    min-height: 7rem;
  }

  .salon_box .Saloon .saloonInfo .saloonFooter {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 120%;
    margin-left: -6rem;
  }

  .mapSide {
    display: none;
  }

  .cardsSide {
    width: 100vw;
    position: relative;
    z-index: 2;
  }

  .cardsSidetop {
    position: fixed;
    top: 75px;
    width: 100vw;
    height: 17vh;
    background: white;
    z-index: 1;
  }

  .cardsSection {
    position: absolute;
    top: calc(18vh);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .cardsSidetop .Searchcontainer {
    position: absolute;
    top: -57px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 60vw;
  }
}

@media (max-width: 1080px) {
  .cardsSide {
    padding-right: 5px;
    padding-left: 5px;
  }

  .cardsSidetop {
    position: fixed;
    top: 90px;
    width: 99vw;
    height: 16vh;
    background: white;
    z-index: 30;
  }

  .cardsSection {
    position: absolute;
    top: calc(17vh);
    display: flex;
  }

  .cardsSidetop .Searchcontainer .Dropdown .Dropdownrow .Dropdowntext {
    max-width: 600px;
    /* Adjust as needed */
  }

  .cardsSidetop .Searchcontainer .Inputgroup {
    position: relative;
    width: 68%;
    margin: auto;
  }

  .cardsSidetop .Searchcontainer {
    position: relative;
    top: 0;
    z-index: 5;
    width: 99vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .cardsSidetop .Searchcontainer .Inputgroup .Input {
    background-color: white;
    border-radius: 25px;
    height: 3.5rem;
    color: black;
    font-size: large;
  }

  .cardsSidetop .Searchcontainer .Inputgroup .Inputright {
    display: flex;
    flex-direction: column;
    height: 3.5rem;
  }

  .cardsSidetop .Searchcontainer .Inputgroup .Inputright .Searchbtn {
    background-color: #23a994;
    width: 90px;
    border-radius: 25px;
  }

  .cardsSidetop .Searchcontainer .Dropdown {
    position: absolute;
    margin-top: 285px;
    width: 38vw;
    /*max-height: 15rem;*/
    max-height: fit-content;
    /*overflow-y: scroll;*/
    background-color: white;
    display: flex;
    border: 0.2px solid rgba(128, 128, 128, 0.121);
    flex-direction: column;
    border-radius: 12px;
    color: black;
    align-items: flex-start;
    /*padding-top: 1rem;*/
    padding-top: 0.3rem;
    padding-bottom: 1rem;
    box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
    z-index: 90;
  }
  .cardsSidetop .Searchcontainer .Dropdown {
    width: 42rem;
  }

  .cardsSidetop .Searchcontainer .Dropdown .Dropdownrow .Dropdowntext {
    max-width: 400px;
    /* Adjust as needed */
  }
}

@media (max-width: 820px) {
  .cardsSidetop .Searchcontainer .Inputgroup {
    position: relative;
    width: 98%;
    margin: auto;
  }

  .cardsSidetop .Searchcontainer .Dropdown {
    width: 80vw;
  }

  .cardsSidetop .Searchcontainer .Dropdown .Dropdownrow .Dropdowntext {
    max-width: 400px;
    /* Adjust as needed */
  }
}

@media (max-width: 660px) {
  .cardsSidetop .Searchcontainer .Inputgroup {
    position: relative;
    width: 23rem;
    margin: auto;
  }
  .cardsSidetop .Searchcontainer .Inputgroup .Input {
    font-size: 15px;
  }

  .cardsSidetop .Searchcontainer .Dropdown {
    width: 90vw;
  }

  .cardsSidetop .Searchcontainer .Dropdown .Dropdownrow .Dropdowntext {
    max-width: 250px;
    /* Adjust as needed */
  }
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0);
  }
}

.jump-animation {
  animation: jump 0.5s ease-in-out;
}
