.Worksch {
  width: 100%;
  height: max-content;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
}
.Worksch .Daycontainer {
  background-color: white;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Worksch .Daycontainer .Day {
  display: flex;
  flex: 0.3;
  justify-items: center;
  align-items: center;
  align-content: center;
}
.Worksch .Daycontainer .Time {
  display: flex;
  flex-direction: row;
  flex: 0.4;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
}
.Worksch .Daycontainer .Offdaycontainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex: 0.3;
  justify-content: flex-end;
  justify-items: center;
  align-items: center;
  align-content: center;
}
.Worksch .hourInput {
  background: #ebeff5;
  padding: 0.3rem;
  border-radius: 0.4rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.Updatebtn {
  background-color: #23a994 !important;
  width: 10rem;
  margin-bottom: 2rem;
}
@media (max-width: 540px) {
  .Worksch .Daycontainer .Day {
    flex: 0.2;
    font-size: small;
  }
  .Worksch .Daycontainer .Time {
    font-size: small;
    flex: 0.6;
    gap: 0.1rem;
    justify-content: flex-start;
  }
  .Worksch .Daycontainer .Offdaycontainer {
    font-size: small;
    flex: 0.2;
    gap: 0;
  }
}
@media (min-width: 540px) and (max-width: 1440px) {
  .Worksch .Daycontainer .Day {
    flex: 0.2;
  }
  .Worksch .Daycontainer .Time {
    flex: 0.6;
  }
  .Worksch .Daycontainer .Offdaycontainer {
    flex: 0.2;
    gap: 0.1rem;
  }
}
