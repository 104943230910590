.Services {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}
.Services .Header {
  font-weight: bolder;
  font-size: large;
}
.Services .Addservicecontainer {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
}
.Services .Addservicecontainer .Addservicebtn {
  background-color: #23a994;
}
.Services .Servicelistcontainer {
  margin-top: 1rem;
}
.Services .Emptylistcontainer {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  align-self: center;
  align-items: center;
  align-content: center;
  width: max-content;
  height: max-content;
  background-color: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(4, 17, 15, 0.16);
}
.Services .Emptylistcontainer .Emptyicon {
  width: 5rem !important;
  height: 5rem !important;
  color: rgba(128, 128, 128, 0.419) !important;
  margin-bottom: 2rem;
}
.Services .Emptylistcontainer .Emptyheader {
  font-size: x-large;
  color: gray;
  font-weight: bold;
  margin-bottom: 1rem;
}
.Services .Emptylistcontainer .Emptytext {
  color: gray;
}
.Services .Servicelistcontainer .Accordion {
  background-color: white;
  border-radius: 12px;
  margin-bottom: 1rem;
}
.Services .Servicelistcontainer .Accordion .Accordionheader {
  font-weight: bold;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.Services .Servicelistcontainer .Accordion .Accordiontbn {
  margin-right: 1rem;
}
.Panelheading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Panelheading .Icon {
  display: flex;
  flex-direction: row;
}
.Panelheading .Icon .Panelicon {
  margin-left: 1rem;
}
.Devider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.ServiceConfirmbtn {
  background-color: #23a994 !important;
  width: 100%;
}
.Subserviceinputdevider {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.Subserviceinput {
  margin-bottom: 1rem;
}
