.Location {
  background-color: white;
  width: 100%;
  height: max-content;
  min-height: 50vh;
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
}
.Location .Locationheader {
  font-weight: bold;
}
.Location .Locationsearchcontainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}
.Location .Locationsearchcontainer .Searchinput {
  display: flex;
  z-index: 20;
  flex: 0.8;
}
.css-13cymwt-control {
  width: 100% !important;
}
.css-t3ipsp-control {
  width: 100% !important;
}
.Location .Locationsearchcontainer .Searchbtn {
  background-color: #23a994;
  display: flex;
  flex: 0.2;
}
.Location .Mapheader {
  font-weight: bold;
  margin-top: 0.5rem;
}
.Location .Mapcontainer {
  z-index: 0;
  margin-top: 1rem;
}
.Location .Devider {
  height: 1px !important;
}
.Location .Updatebtn {
  background-color: #23a994;
  width: 10rem;
}
