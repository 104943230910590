.BusinessStarter {
  background-color: white;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.BusinessStarter .Starterleft {
  display: flex;
  flex: 0.5;
  flex-direction: column;
}
.BusinessStarter .Starterright {
  display: flex;
  flex: 0.5;
}
.BusinessStarter .Starterright .starterimg {
  min-width: 30rem;
  max-width: 40rem;
}
.BusinessStarter .Starterleft .Starterheader {
  padding-left: 4rem;
  font-size: 3.5rem;
  font-weight: 1000;
}
.BusinessStarter .Starterleft .Starterheader .Green {
  color: #23a994;
}
.BusinessStarter .Starterleft .Startertext {
  margin-top: 1rem;
  padding-left: 4rem;
  text-justify: distribute;
  padding-right: 8rem;
  color: #4d4d4d;
}
.BusinessStarter .Starterleft .Starterbtns {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding-left: 4rem;
  margin-top: 2rem;
}
.BusinessStarter .Starterleft .Starterbtns .Registerbtn {
  background-color: #23a994;
}
.BusinessStarter .Starterleft .Starterbtns .Contactbtn {
  border-color: #23a994;
}
.BusinessStarter .Starterleft .Starterchecks {
  display: flex;
  margin-left: 4rem;
  flex-direction: row;
  gap: 1rem;
  margin-top: 4rem;
}
.BusinessStarter .Starterleft .Starterchecks .Checkmark {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-self: center;
  align-items: center;
  align-content: center;
}
.BusinessStarter .Starterleft .Starterchecks .Checkmark .Checkicon {
  color: #fe6b28 !important;
}
.BusinessStarter .Starterleft .Starterchecks .Checkmark .Checktxt {
  font-weight: bold;
}
@media (max-width: 1311px) {
  .BusinessStarter .Starterright {
    display: none;
    flex: 0;
  }
  .BusinessStarter {
    flex-direction: row;
  }
  .BusinessStarter .Starterleft {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .BusinessStarter .Starterleft .Starterheader {
    padding-left: 0;
    font-size: 3.5rem;
    font-weight: 1000;
  }
  .BusinessStarter .Starterleft .Startertext {
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
    color: #4d4d4d;
    width: 34rem;
    text-justify: distribute;
  }
  .BusinessStarter .Starterleft .Starterbtns {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 34rem;
    padding-left: 0;
    margin-top: 2rem;
  }
  .BusinessStarter .Starterleft .Starterchecks {
    display: flex;
    margin-left: 4rem;
    flex-direction: row;
    gap: 1rem;
    margin-top: 4rem;
  }
}

@media (max-width: 600px) {
  .BusinessStarter .Starterleft .Starterchecks {
    display: flex;
    margin-left: 0;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    margin-top: 4rem;
  }
  .BusinessStarter .Starterleft .Starterchecks .Checkmark {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-self: flex-start;
    align-items: center;
    align-content: center;
    margin-left: 1rem;
  }
  .BusinessStarter .Starterleft .Starterbtns {
    margin-left: 1rem;
  }
  .BusinessStarter .Starterleft .Startertext {
    margin-left: 1rem;
  }
  .BusinessStarter .Starterleft .Starterheader {
    margin-left: 1rem;
  }
}
