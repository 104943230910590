.Vilkar {
  display: flex;
  flex-direction: column;
  height: max-content;
}
.Vilkar .Topnaviguation {
  position: sticky;
}
.Vilkar .Footer {
  height: max-content;
}
.Vilkar .Content {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  padding-left: 10rem;
  padding-right: 10rem;
  margin-bottom: 5rem;
}
.Vilkar .Content .Main_header {
  font-size: 3rem;
  font-weight: 700;
  color: #23a994;
}
.Vilkar .Content .Subheader {
  color: gray;
  font-weight: 500;
  margin-bottom: 4rem;
}
.Vilkar .Content .Header {
  font-size: xx-large;
  font-weight: 700;
  color: #23a994;
  margin-bottom: 0.5rem;
}
.Vilkar .Content .Text {
  margin-bottom: 3rem;
  text-align: justify;
  color: black;
  font-weight: 500;
}
@media (max-width: 1000px) {
  .Vilkar .Content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
