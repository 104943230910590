.Otp {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}
.Otp .Otpcontainer {
  display: flex;
  flex: 0.6;
  flex-direction: column;
}
.Otp .Loginright {
  display: flex;
  flex: 0.4;
}
.Otp .Otpcontainer .Logocontainer {
  width: 13rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.Otp .Otpcontainer .Header {
  margin-left: 6.5rem;
  font-weight: 600;
  font-size: xx-large;
  margin-top: 4rem;
}
.Otp .Otpcontainer .Subheader {
  margin-left: 6.5rem;
  margin-top: 2rem;
  color: #4d4d4d;
}
.Otp .Otpcontainer .Pininput {
  margin-left: 7rem;
  margin-top: 3rem;
}
.Otp .Otpcontainer .Pininput .pin {
  background-color: #f0f0f0 !important;
  width: 5rem !important;
  height: 5rem !important;
  margin-right: 2.5rem;
}
.Otp .Otpcontainer .Submitbtn {
  width: 30rem;
  margin-left: 6.5rem;
  margin-top: 3rem;
  background-color: #23a994;
}
@media (max-width: 1400px) {
  .Otp .Otpcontainer {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Otp .Loginright {
    display: flex;
    flex: 0.45;
  }
  .Otp .Otpcontainer .Logocontainer {
    width: 13rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .Otp .Otpcontainer .Header {
    margin-left: 4rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }
  .Otp .Otpcontainer .Subheader {
    margin-left: 4rem;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Otp .Otpcontainer .Pininput {
    margin-left: 4rem;
    margin-top: 3rem;
  }
  .Otp .Otpcontainer .Pininput .pin {
    background-color: #f0f0f0 !important;
    width: 5rem !important;
    height: 5rem !important;
    margin-right: 2.5rem;
  }
  .Otp .Otpcontainer .Submitbtn {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 3rem;
    background-color: #23a994;
  }
}
@media (max-width: 1100px) {
  .Otp .Otpcontainer {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Otp .Loginright {
    display: flex;
    flex: 0.45;
  }
  .Otp .Otpcontainer .Logocontainer {
    width: 13rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .Otp .Otpcontainer .Header {
    margin-left: 2rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }
  .Otp .Otpcontainer .Subheader {
    margin-left: 2rem;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Otp .Otpcontainer .Pininput {
    margin-left: 2rem;
    margin-top: 3rem;
  }
  .Otp .Otpcontainer .Pininput .pin {
    background-color: #f0f0f0 !important;
    width: 5rem !important;
    height: 5rem !important;
    margin-right: 2.5rem;
  }
  .Otp .Otpcontainer .Submitbtn {
    width: 30rem;
    margin-left: 2rem;
    margin-top: 3rem;
    background-color: #23a994;
  }
}
@media (max-width: 960px) {
  .Otp .Otpcontainer {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Otp .Loginright {
    display: none;
  }
  .Otp .Otpcontainer .Logocontainer {
    width: 13rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .Otp .Otpcontainer .Header {
    margin-left: 4rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }
  .Otp .Otpcontainer .Subheader {
    margin-left: 4rem;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Otp .Otpcontainer .Pininput {
    margin-left: 4rem;
    margin-top: 3rem;
  }
  .Otp .Otpcontainer .Pininput .pin {
    background-color: #f0f0f0 !important;
    width: 5rem !important;
    height: 5rem !important;
    margin-right: 2.5rem;
  }
  .Otp .Otpcontainer .Submitbtn {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 3rem;
    background-color: #23a994;
  }
}
@media (max-width: 540px) {
  .Otp .Otpcontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .Otp .Loginright {
    display: none;
  }
  .Otp .Otpcontainer .Logocontainer {
    width: 13rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .Otp .Otpcontainer .Header {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }
  .Otp .Otpcontainer .Subheader {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Otp .Otpcontainer .Pininput {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 3rem;
  }
  .Otp .Otpcontainer .Pininput .pin {
    background-color: #f0f0f0 !important;
    width: 21vw !important;
    height: 21vw !important;
    margin-right: 3vw;
  }
  .Otp .Otpcontainer .Submitbtn {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 3rem;
    background-color: #23a994;
  }
}
