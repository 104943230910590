.Footer {
  display: flex;
  flex-direction: column;
  background-color: #292929;
  min-height: 30vh;
  max-height: max-content;
  padding-top: 3.5rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: white;
  width: 100%;
  gap: 2rem;
  font-family: "Poppins", sans-serif;
}
.Footer .Header {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}
.Footer .Buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
}
.Footer .Buttons .Btn {
  font-size: large;
  padding: 1.5rem;
  border-radius: 12px;
}
.Footer .Buttons .Btn:hover {
  color: black;
}
.Footer .Links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8rem;
}
.Footer .Links .Linkitems {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}
.Footer .Links .Linkitems .link {
  cursor: pointer;
  color: rgb(219, 206, 206);
}
.Footer .Links .Linkitems .link:hover {
  color: white;
}
.Footer .Links .Logocontainer {
  padding: 0;
  align-self: center;
}
.Footer .Links .Logocontainer .Logo {
  width: 12rem;
  padding: 0;
}
.Footer .Links .Socialmedia {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.Footer .Links .Socialmedia .Icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
@media (max-width: 1030px) {
  .Footer .Links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }
  .Footer .Links .Linkitems {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .Footer .Links .Socialmedia {
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}
