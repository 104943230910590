.Newpass {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}
.Newpass .Newpasscontainer {
  display: flex;
  flex: 0.6;
  flex-direction: column;
}
.Newpass .Loginright {
  display: flex;
  flex: 0.4;
}
.Newpass .Newpasscontainer .Logocontainer {
  width: 13rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.Newpass .Newpasscontainer .Header {
  margin-left: 6.5rem;
  font-weight: 600;
  font-size: xx-large;
  margin-top: 4rem;
}
.Newpass .Newpasscontainer .Subheader {
  margin-left: 6.5rem;
  margin-top: 2rem;
  color: #4d4d4d;
}
.Newpass .Newpasscontainer .Emailabel {
  margin-left: 6.5rem;
  margin-top: 4rem;
}
.Newpass .Newpasscontainer .Emailinput {
  width: 30rem;
  margin-left: 6.5rem;
  margin-top: 1rem;
}
.Newpass .Newpasscontainer .Passlabel {
  margin-left: 6.5rem;
  margin-top: 2rem;
}
.Newpass .Newpasscontainer .Passinput {
  width: 30rem;
  margin-left: 6.5rem;
  margin-top: 1rem;
}
.Newpass .Newpasscontainer .Submitbtn {
  width: 30rem;
  margin-left: 6.5rem;
  margin-top: 2rem;
  background-color: #23a994;
}
@media (max-width: 1400px) {
  .Newpass .Newpasscontainer {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Newpass .Loginright {
    display: flex;
    flex: 0.45;
  }
  .Newpass .Newpasscontainer .Logocontainer {
    width: 13rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .Newpass .Newpasscontainer .Header {
    margin-left: 4rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }
  .Newpass .Newpasscontainer .Subheader {
    margin-left: 4rem;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Newpass .Newpasscontainer .Emailabel {
    margin-left: 4rem;
    margin-top: 4rem;
  }
  .Newpass .Newpasscontainer .Emailinput {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 1rem;
  }
  .Newpass .Newpasscontainer .Passlabel {
    margin-left: 4rem;
    margin-top: 2rem;
  }
  .Newpass .Newpasscontainer .Passinput {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 1rem;
  }
  .Newpass .Newpasscontainer .Submitbtn {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 2rem;
    background-color: #23a994;
  }
}
@media (max-width: 1100px) {
  .Newpass .Newpasscontainer {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Newpass .Loginright {
    display: flex;
    flex: 0.45;
  }
  .Newpass .Newpasscontainer .Logocontainer {
    width: 13rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .Newpass .Newpasscontainer .Header {
    margin-left: 2rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }
  .Newpass .Newpasscontainer .Subheader {
    margin-left: 2rem;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Newpass .Newpasscontainer .Emailabel {
    margin-left: 2rem;
    margin-top: 4rem;
  }
  .Newpass .Newpasscontainer .Emailinput {
    width: 30rem;
    margin-left: 2rem;
    margin-top: 1rem;
  }
  .Newpass .Newpasscontainer .Passlabel {
    margin-left: 2rem;
    margin-top: 2rem;
  }
  .Newpass .Newpasscontainer .Passinput {
    width: 30rem;
    margin-left: 2rem;
    margin-top: 1rem;
  }
  .Newpass .Newpasscontainer .Submitbtn {
    width: 30rem;
    margin-left: 2rem;
    margin-top: 2rem;
    background-color: #23a994;
  }
}

@media (max-width: 960px) {
  .Newpass .Newpasscontainer {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Newpass .Loginright {
    display: none;
  }
  .Newpass .Newpasscontainer .Logocontainer {
    width: 13rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .Newpass .Newpasscontainer .Header {
    margin-left: 4rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }
  .Newpass .Newpasscontainer .Subheader {
    margin-left: 4rem;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Newpass .Newpasscontainer .Emailabel {
    margin-left: 4rem;
    margin-top: 4rem;
  }
  .Newpass .Newpasscontainer .Emailinput {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 1rem;
  }
  .Newpass .Newpasscontainer .Passlabel {
    margin-left: 4rem;
    margin-top: 2rem;
  }
  .Newpass .Newpasscontainer .Passinput {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 1rem;
  }
  .Newpass .Newpasscontainer .Submitbtn {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 2rem;
    background-color: #23a994;
  }
}

@media (max-width: 540px) {
  .Newpass .Newpasscontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .Newpass .Loginright {
    display: none;
  }

  .Newpass .Newpasscontainer .Header {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }
  .Newpass .Newpasscontainer .Subheader {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Newpass .Newpasscontainer .Emailabel {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 4rem;
  }
  .Newpass .Newpasscontainer .Emailinput {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 1rem;
  }
  .Newpass .Newpasscontainer .Passlabel {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 2rem;
  }
  .Newpass .Newpasscontainer .Passinput {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 1rem;
  }
  .Newpass .Newpasscontainer .Submitbtn {
    width: 95vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 2rem;
    background-color: #23a994;
  }
}
