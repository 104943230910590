.AppointmentPage {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  min-height: 50vh;
  font-family: "Poppins", sans-serif;
}

.AppointmentPage .Loading_animation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.AppointmentPage .Loading_animation .Loading_logo {
  width: 15rem;
  height: 17rem;
}

.AppointmentPage .toppart {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-bottom: 1rem;
  align-items: center;
}

.AppointmentPage .toppart .Previous {
  display: flex;
  width: max-content;
  height: max-content;
}

.AppointmentPage .toppart .Previous .Previousbtn {
  border-radius: 50%;
}

.AppointmentPage .toppart .Previous .Previousbtn .Previousicon {
  width: 1.5rem;
  height: 1.5rem;
}

.AppointmentPage .toppart .Header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  font-size: larger;
  font-weight: bolder;
}

.AppointmentPage .bottomContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  width: 100%;
  min-height: 50vh;
  padding-left: 14rem;
  padding-right: 14rem;
}

.AppointmentPage .bottomContainer .cardContainer {
  display: flex;
  flex-direction: column;
  flex: 0.6;
  gap: 1rem;
}

.AppointmentPage .bottomContainer .cardContainer .writeReview {
  text-decoration: underline;
  font-size: large;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.AppointmentPage .bottomContainer .cardContainer .Main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-height: max-content;
  padding: 1rem;
}

.AppointmentPage .bottomContainer .cardContainer .Status {
  font-size: large;
  font-weight: bold;
  color: #fe6b28;
}

.AppointmentPage .bottomContainer .cardContainer .appointmentInfo {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(211, 211, 211, 0.455);
}

.AppointmentPage .bottomContainer .cardContainer .appointmentInfo .Info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-self: flex-end;
}

.AppointmentPage .bottomContainer .cardContainer .appointmentInfo .Info .Time {
  font-weight: 700;
}

.AppointmentPage .bottomContainer .cardContainer .appointmentInfo .Info .Adress {
  font-weight: 500;
  color: #23a994;
}

.AppointmentPage .bottomContainer .cardContainer .subserviceInfo {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.AppointmentPage .bottomContainer .cardContainer .subserviceInfo .checkIcon {
  margin-top: 0.1rem;
  width: 1.5rem;
  height: 1.5rem;
  color: #23a994;
}

.AppointmentPage .bottomContainer .cardContainer .subserviceInfo .Info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-self: flex-start;
}

.AppointmentPage .bottomContainer .cardContainer .subserviceInfo .Info .Name {
  font-weight: bold;
}

.AppointmentPage .bottomContainer .cardContainer .subserviceInfo .Info .subInfo {
  font-weight: bold;
  color: gray;
}

.AppointmentPage .bottomContainer .cardContainer .subserviceInfo .Info .subInfo .moreInfo {
  cursor: pointer;
}

.AppointmentPage .bottomContainer .cardContainer .subserviceInfo .Info .subInfo .moreInfo:hover {
  text-decoration: underline;
}

.AppointmentPage .bottomContainer .cardContainer .staffInfo {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  border-bottom: 1px solid rgba(211, 211, 211, 0.455);
}

.AppointmentPage .bottomContainer .cardContainer .staffInfo .checkIcon {
  margin-top: 0.1rem;
  width: 1.5rem;
  height: 1.5rem;
  color: #23a994;
}

.AppointmentPage .bottomContainer .cardContainer .staffInfo .Name {
  font-weight: bold;
  font-size: large;
}

.AppointmentPage .bottomContainer .cardContainer .paymentContainer {
  border-bottom: 1px solid rgba(211, 211, 211, 0.455);
  padding-bottom: 1.7rem;
  padding-top: 1rem;
}

.AppointmentPage .bottomContainer .cardContainer .totalContainer {
  padding-bottom: 1.7rem;
  padding-top: 1rem;
}

.AppointmentPage .bottomContainer .saloonInfo {
  display: flex;
  flex-direction: column;
  flex: 0.4;
  gap: 1rem;
}

.AppointmentPage .bottomContainer .saloonInfo .saloonName {
  font-weight: bold;
  font-size: larger;
  cursor: pointer;
}

.AppointmentPage .bottomContainer .saloonInfo .saloonName:hover {
  text-decoration: underline;
}

.AppointmentPage .bottomContainer .saloonInfo .saloonInformation {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.AppointmentPage .bottomContainer .saloonInfo .saloonInformation .Header {
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.AppointmentPage .bottomContainer .saloonInfo .saloonInformation .Phone {
  color: gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-weight: bold;
}

.AppointmentPage .bottomContainer .saloonInfo .saloonInformation .Email {
  color: gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-weight: bold;
}

.submit_review_btn {
  width: 100%;
  background-color: #23a994;
  margin-bottom: 1rem;
}

.stars_review {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1210px) {
  .AppointmentPage .bottomContainer {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media (max-width: 777px) {
  .AppointmentPage .bottomContainer {
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media (max-width: 690px) {
  .AppointmentPage .bottomContainer {
    flex-direction: column;
    align-items: center;
  }
}