.HowItWorks {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.HowItWorks .title {
  font-weight: bolder;
  font-size: 2rem;
  text-shadow: 0px 1px, 1px 0px, 1px 1px;
}
.HowItWorks .items {
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.HowItWorks .items .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.HowItWorks .items .item .number {
  font-weight: bolder;
  font-size: 4rem;
  color: #23a994;
  text-shadow: 0px 1px, 1px 0px, 1px 1px;
  margin-bottom: 1.2rem;
}
.HowItWorks .items .item .sub_title {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1.1rem;
  text-shadow: 0, 0, 0, 0;
}
.HowItWorks .items .item .paragraph {
  text-align: center;
  align-self: center;
  padding-left: 2rem;
  padding-right: 2rem;
  text-justify: distribute;
}
.itemAsIcon {
  transform: rotate(45deg);
  font-size: 3rem;
  margin-bottom: 3rem;
  color: #c2c2c2;
}
@media (max-width: 1160px) {
  .HowItWorks {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 760px) {
  .HowItWorks {
    height: fit-content;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-top: 1rem;
  }
  .HowItWorks .items {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .itemAsIcon {
    transform: rotate(135deg);
    font-size: 3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
