.Card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0.5rem;
  padding: 1.4rem;
  margin-top: 0.9rem;
  width: 50rem;
  min-width: 20rem;
}
.Card .Infosectioncontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Card .Infosectioncontainer .Infosection {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.Card .Infosectioncontainer .Infosection .cardinfo {
  margin-left: 0.9rem;
  display: flex;
  flex-direction: column;
  font-size: small;
  align-self: center;
}
.Card .cardinfo .bottomPart {
  display: flex;
  margin-top: 0.9rem;
}
.Card .cardinfo .cardnumber {
  font-weight: bold;
  font-size: 1.2rem;
}
.Card .cardinfo .cardholder,
.Card .cardinfo .cardexpiration {
  display: flex;
  align-items: center;
  margin-right: 0.7rem;
}
.Card .cardinfo .icon {
  margin-right: 0.2rem;
}
@media (max-width: 960px) {
  .Card {
    width: 99%;
    margin: auto;
    margin-top: 1.2rem;
    padding: 0.7rem;
  }
  .Card .cardinfo .bottomPart {
    display: flex;
    margin-top: 0.3rem;
    flex-direction: column;
  }
}
