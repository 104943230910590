.Album {
  background-color: white;
  width: 100%;
  height: max-content;
  min-height: 50vh;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
}
.Album .Albumheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
}
.Album .Galleriecontainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  margin-top: 2rem;
}
.Album .Galleriecontainer .Galleryitem {
  display: flex;
  flex-direction: column;
}
.Album .Galleriecontainer .Galleryitem .Image {
  min-width: 13rem;
  max-width: 16rem;
}
.Album .Galleriecontainer .Galleryitem .Buttonscontainer {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.Updatebtn {
  margin-top: 1rem;
  width: 10rem;
}
@media (max-width: 1440px) {
  .Album .Galleriecontainer .Galleryitem .Image {
    min-width: 11rem;
    max-width: 14rem;
  }
}
@media (max-width: 940px) {
  .Album .Galleriecontainer {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
@media (max-width: 740px) {
  .Album .Galleriecontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}
