.isScrolled {
  position: fixed;
  background: white;
  top: 0;
  width: 100%;
}
@media (max-width: 1260px) {
  .navbar {
    position: relative;
    z-index: 9999;
    top: 0;
    height: 90px;
  }
  .isScrolled {
    position: fixed;
    background: white;
    top: 0;
    width: 100%;
    z-index: 999;
    height: 90px;
  }
}
