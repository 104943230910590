.Settings {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}
.Settings .Header {
  font-weight: bolder;
  font-size: large;
}
.Settings .Settingscontainer {
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;
}
.Settings .Settingscontainer .Settingsleftbar {
  display: flex;
  flex: 0.3;
}
.Settings .Settingscontainer .Settingscontent {
  display: flex;
  flex: 0.7;
}
@media (max-width: 1440px) {
  .Settings .Settingscontainer .Settingsleftbar {
    display: flex;
    flex: 0.3;
  }
  .Settings .Settingscontainer .Settingscontent {
    display: flex;
    flex: 0.7;
  }
}
@media (max-width: 1370px) {
  .Settings .Settingscontainer .Settingsleftbar {
    display: none;
  }
  .Settings .Settingscontainer .Settingscontent {
    display: flex;
    flex: 1;
  }
}
