.BillingHistory .Downloadlink {
  color: #23a994;
}
.BillingHistory .paid {
  width: fit-content;
  height: fit-content;
  background: #eaf7f5;
  padding: 0.5rem;
  border-radius: 0.3rem;
}
.BillingHistory .unpaid {
  width: fit-content;
  height: fit-content;
  background: #f7bbb1;
  padding: 0.5rem;
  border-radius: 0.3rem;
}
.BillingHistory .invoice {
  display: flex;
  align-items: center;
}
