.Payment {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}
.Payment .Header {
  font-weight: bolder;
  font-size: large;
}
.Cardheader {
  font-weight: bold;
  font-size: medium;
}
.Tableheader {
  font-weight: bold;
  font-size: medium;
}
.Payment .cardsContainer {
  background-color: white;
  border-radius: 12px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Payment .BillingHistoryContainer {
  background-color: white;
  border-radius: 12px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.Payment .Tablecontainer {
  margin-top: 1rem;
}
.Payment .Tablecontainer .Table {
  margin-top: 1rem;
}
.Payment .cardsContainer .TopHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Payment .cardsContainer .TopHeader .AddPaymentBtn {
  background: #23a994;
  font-weight: bold;
  display: flex;
  font-size: large;
  /*width: 14rem;*/
  justify-content: space-between;
  align-items: center;
  /*padding: 1.5rem;*/
}
.Payment .Thead .Th {
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
  color: black;
  font-size: 1.2rem;
}
.Payment .doubleInput {
  display: flex;
  flex-direction: row;
  background: red;
}

@media (max-width: 760px) {
  .Payment .cardsContainer .TopHeader .AddPaymentBtn {
    background: #23a994;
    font-weight: bold;
    display: flex;
    font-size: large;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
}
