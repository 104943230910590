.Settingsleftbar {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.Settingsleftbar .Settingslink {
  margin-bottom: 1rem;
}
.Settingsleftbar .Settingslink:hover {
  font-weight: bold;
}
.Settingsleftbar .Settingsdevider {
  height: 0.1rem;
  width: 15rem;
  margin-bottom: 1rem;
  background-color: #c2c2c2;
}
.Settingsleftbar .Settingscancel {
  color: #fe6b28;
}
