.Generalinfo {
  background-color: white;
  width: 100%;
  height: max-content;
  min-height: 50vh;
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
}
.Generalinfo .title {
  font-weight: bold;
}
.Generalinfo .TopMan {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.Generalinfo .uploadBtn {
  border: #4db9a8 2px solid;
  background: none;
  padding: 1.4rem;
}
.Generalinfo .deleteBtn {
  border: white 2px solid;
  background: none;
  padding: 1rem;
  color: #fe6b28;
}
.Generalinfo .Label {
  width: 100%;
  margin-bottom: 0;
  align-items: flex-start;
}
.Generalinfo .LabelDoubleInput {
  width: 100%;
  margin-bottom: 0;
  align-items: flex-start;
}
.Generalinfo .SalonNameInput {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.Generalinfo .Submitbtn {
  width: 10rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #23a994;
}
.Generalinfo .doubleInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
}
.Generalinfo .cityInput {
  /* width: 39%; */
  flex: 0.38;
}
.Generalinfo .addressInput {
  flex: 0.59;
}
@media (max-width: 760px) {
  .Generalinfo {
    background-color: white;
    width: 95%;
    margin: auto;
  }
  .Generalinfo .TopMan {
    width: 100%;
    height: 15rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
