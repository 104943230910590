.Cancelaccount {
  background-color: white;
  width: 100%;
  height: max-content;
  min-height: 50vh;
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  gap: 0.5rem;
}
.Cancelaccount .Header {
  font-weight: bold;
}
.Cancelaccount .Subheader {
  margin-bottom: 1rem;
}
.Cancelaccount .Reason {
  margin-bottom: 1rem;
}
.Cancelaccount .Comment {
  margin-bottom: 1rem;
}
.Updatebtn {
  background-color: #23a994;
  width: 10rem;
}
