.topNavbar {
  position: fixed;
  display: flex;
  z-index: 90;
  top: 0;
  background: white;
  width: 100%;
  height: fit-content;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-direction: row;
}
.topNavbar .Navbarleft {
  display: flex;
  flex: 0.3;
  align-self: center;
  justify-content: flex-start;
}
.topNavbar .Navbarleft .Logo {
  width: 14rem;
}
.topNavbar .Navbarmiddle {
  padding-top: 0.3rem;
  display: flex;
  flex: 0.4;
  align-self: center;
  flex-direction: row;
  justify-content: center;
  justify-content: space-evenly;
}
.topNavbar .Navbarmiddle .Navlink {
  font-size: medium;
  cursor: pointer;
}
.topNavbar .Navbarmiddle .Navlink:hover {
  font-weight: bold;
}
.topNavbar .Navbarright {
  display: flex;
  flex: 0.3;
  align-self: center;
  justify-content: center;
  flex-direction: row;
  gap: 2rem;
}
.topNavbar .Navbarright .Signupbtn {
  border-color: #fe6b28 !important;
}
.Mobiletopnav {
  display: none;
}
@media (max-width: 1340px) {
  .topNavbar {
    display: none;
  }
  .Mobiletopnav {
    position: fixed;
    display: flex;
    z-index: 90;
    top: 0;
    background: white;
    width: 100%;
    height: fit-content;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-direction: row;
    gap: 2rem;
  }
  .Mobiletopnav .Logo {
    width: 13rem;
  }
  .Nav {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
  .Nav .Navitem {
    display: flex;
    margin-bottom: 2rem;
    flex-direction: row;
    margin-left: 1rem;
    font-size: large;
    font-weight: 500;
  }
  .Nav .Navitem .Navicon {
    align-self: center;
    margin-right: 1rem;
  }
}
