.Statistic {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}
.Statistic .Header {
  font-weight: bolder;
  font-size: large;
  margin-bottom: 3rem;
}
.Statistic .Headcontainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
}
.Statistic .Headcontainer .Headerleft {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 0.5rem;
}
.Statistic .Headcontainer .Headerleft .Inputcontainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.Statistic .Headcontainer .Headerleft .Inputcontainer .Label {
  align-self: center;
  min-width: fit-content;
  font-weight: bold;
}
.Statistic .Headcontainer .Headerleft .Inputcontainer .Input {
  background-color: white;
  align-self: center;
}
.Statistic .Headcontainer .Headerleft .Inputcontainer .filterbtn {
  background-color: #23a994;
}

.Statistic .Headcontainer .Headerright {
  display: flex;
  flex-direction: row;
  flex: 0.2;
  gap: 0.5rem;
  justify-content: flex-end;
}
.Statistic .Headcontainer .Headerright .Inputcontainer {
  display: flex;
  flex-direction: row;
}
.Statistic .Headcontainer .Headerright .Inputcontainer .Exportbtn {
  width: fit-content;
  height: fit-content;
}
.Statistic .Table {
  background-color: white;
  border-radius: 12px;
  padding: 0.5rem;
}
.Consult {
  color: #23a994;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 940px) {
  .Statistic .Headcontainer .Headerleft {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex: 1;
    gap: 50px;
    gap: 0.5rem;
  }
}

@media (max-width: 640px) {
  .Statistic .Headcontainer .Headerleft {
    display: grid;
    grid-template-columns: 1fr;
    flex: 1;
    gap: 50px;
    gap: 0.5rem;
  }
  .Statistic .Headcontainer .Headerleft .Inputcontainer .filterbtn {
    background-color: #23a994;
    margin: auto;
  }
  .Statistic .Headcontainer {
    display: flex;
    flex-direction: column;
  }
  .Statistic .Headcontainer .Headerright {
    display: flex;
    flex-direction: row;
    flex: 0.2;
    gap: 0.5rem;
    justify-content: center;
    margin-top: 2rem;
  }
}
