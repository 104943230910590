.Booking {
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}
.booking_popup_icon {
  margin-top: 1rem;
  font-size: 2.5rem;
}
.booking_popup_title {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  opacity: 90%;
}
.booking_popup_text {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 600;
  color: gray;
  margin-bottom: 0.8rem;
}
.booking_popup_footer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  color: gray;
}
.Booking .Booking_Container {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  background-color: white;
  min-height: 100vh;
  max-height: max-content;
  width: 100%;
  gap: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.Booking .Loading_animation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.Booking .Loading_animation .Loading_logo {
  width: 15rem;
  height: 17rem;
}
.Booking .Head_Logocontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
}
.Booking .Head_Logocontainer .HeadLogo {
  display: flex;
  align-self: center;
  width: 14rem;
}
.Booking .Headercontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: max-content;
  gap: 1rem;
  align-items: center;
}
.Booking .Headercontainer .Previous {
  display: flex;
  width: max-content;
  height: max-content;
}
.Booking .Headercontainer .Previous .Previousbtn {
  border-radius: 50%;
}
.Booking .Headercontainer .Previous .Previousbtn .Previousicon {
  width: 1.5rem;
  height: 1.5rem;
}
.Booking .Headercontainer .Header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
}
.Booking .Headercontainer .Header .Topheader {
  font-size: larger;
  font-weight: bold;
}
.Booking .Headercontainer .Header .Subheader {
  font-size: medium;
  font-weight: bold;
  color: gray;
}
.Booking .Bodycontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: max-content;
}
.Booking .Bodycontainer .Staffcontainer {
  display: flex;
  flex-direction: column;
  flex: 0.25;
  gap: 1rem;
}
.Booking .Bodycontainer .Staffcontainer .Staffheader {
  font-size: large;
  font-weight: bold;
}
.Booking .Bodycontainer .Staffcontainer .Stafflist {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.Booking .Bodycontainer .Staffcontainer .Stafflist .Staff {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  border-radius: 12px;
  min-height: 7rem;
}
.Booking .Bodycontainer .Staffcontainer .Stafflist .Staff .Checkstaff {
  display: flex;
  align-self: center;
  width: max-content;
  height: max-content;
}
.Booking
  .Bodycontainer
  .Staffcontainer
  .Stafflist
  .Staff
  .Checkstaff
  .Radiobtn {
  width: 2rem;
  height: 2rem;
}
.Booking .Bodycontainer .Staffcontainer .Stafflist .Staff .Staffimage {
  display: flex;
  align-self: center;
  max-width: max-content;
  max-height: max-content;
}
.Booking .Bodycontainer .Staffcontainer .Stafflist .Staff .Staffimage .Image {
  width: 6rem;
  border-radius: 12px;
  object-fit: cover;
}
.Booking .Bodycontainer .Staffcontainer .Stafflist .Staff .Staffinfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.Booking .Bodycontainer .Staffcontainer .Stafflist .Staff .Staffinfo .Name {
  font-size: x-large;
  font-weight: bold;
}
.Booking
  .Bodycontainer
  .Staffcontainer
  .Stafflist
  .Staff
  .Staffinfo
  .Occupation {
  font-size: large;
  font-weight: bold;
  color: gray;
}
.Booking .Bodycontainer .Calendarcontainer {
  display: flex;
  flex-direction: column;
  flex: 0.75;
  gap: 1rem;
}
.Booking .Bodycontainer .Calendarcontainer .Calendarheader {
  font-size: large;
  font-weight: bold;
}
.Booking .Bodycontainer .Calendarcontainer .Booking_Stepper {
  width: 30%;
  align-self: center;
}
.Booking .Bodycontainer .Calendarcontainer .Calendarbody {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-height: 80vh;
  max-height: max-content;
  position: relative;
}
.Booking .Bodycontainer .Calendarcontainer .Calendarbody .Calendar_Bottom {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Calendar_Bottom
  .Checkout {
  background-color: #23a994;
  font-size: larger;
  font-weight: bold;
}
.Booking .Bodycontainer .Calendarcontainer .Calendarbody .Viewchanger {
  position: absolute;
  top: 0;
  left: 0;
}
.Booking .Bodycontainer .Calendarcontainer .Calendarbody .rdp {
  --rdp-cell-size: 70px;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
  --rdp-accent-color: #23a994;
}
.Booking .Bodycontainer .Calendarcontainer .Calendarbody .Weekcalendar {
  width: 90%;
  min-height: 70vh;
  max-height: max-content;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekheader
  .Leftheader
  .Prevbtn {
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  font-size: 1rem;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekheader
  .Leftheader
  .Prevbtn
  .Btnicon {
  display: flex;
  align-self: center;
  width: 1.5rem;
  height: 1.5rem;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekheader
  .Rightheader
  .Nextbtn {
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  font-size: 1rem;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekheader
  .Rightheader
  .Nextbtn
  .Btnicon {
  display: flex;
  align-self: center;
  width: 1.5rem;
  height: 1.5rem;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekbody {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-right: 0.05px dotted #23a994;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekbody
  .Day {
  display: flex;
  flex-direction: column;
  border-left: 0.05px dotted #23a994;
  border-top: 0.05px dotted #23a994;
  border-bottom: 0.05px dotted #23a994;
  width: 100%;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekbody
  .Day
  .Topday {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  height: 8rem;
  font-weight: bold;
  font-size: large;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekbody
  .Day
  #Offday {
  background-color: #f7f7fa !important;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekbody
  .Day
  .Bottomday {
  padding-top: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  height: 100%;
  padding-bottom: 1rem;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekbody
  .Day
  .Bottomday
  .Timeslot {
  border: 1px solid #23a994;
  border-radius: 12px;
  padding: 0.5rem;
  font-weight: bold;
  width: 60%;
  margin: auto;
}
.Booking
  .Bodycontainer
  .Calendarcontainer
  .Calendarbody
  .Weekcalendar
  .Weekbody
  .Day
  .Bottomday
  .TimeslotActive {
  border: 1px solid #23a994;
  border-radius: 12px;
  width: 60%;
  margin: auto;
  padding: 0.5rem;
  font-weight: bold;
  position: relative;
  background: #23a994;
  color: white;
}
.Booking .mobile {
  display: none;
}

.text_timeslot {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  font-size: medium;
  position: absolute;
  z-index: 77;
  left: 8px;
  top: 0;
}
@media (max-width: 934px) {
  .Booking .notmobile {
    display: none;
  }
  .Booking .mobile {
    display: block;
  }
  .Booking .Bodycontainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: max-content;
  }
  .Booking .Bodycontainer .Staffcontainer {
    display: none;
  }
  .Booking .Bodycontainer .Calendarcontainer {
    flex: 1;
  }
  .Booking .Bodycontainer .Calendarcontainer .Calendarbody .Weekcalendar {
    width: 100%;
  }
  .Booking {
    width: 100vw;
    padding: 0;
  }
  /* .booking_popup_icon {
    margin-top: 1rem;
    font-size: 2.5rem;
  }
  .booking_popup_title {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    opacity: 90%;
  }
  .booking_popup_text {
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: gray;
    margin-bottom: 0.8rem;
  }
  .booking_popup_footer {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    color: gray;
  } */
  .Booking .Booking_Container {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  /* .Booking .Loading_animation {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
  } */
  /* .Booking .Loading_animation .Loading_logo {
    width: 15rem;
    height: 17rem;
  } */
  .Booking .Head_Logocontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
  }
  .Booking .Head_Logocontainer .HeadLogo {
    display: flex;
    align-self: center;
    width: 14rem;
  }
  .Booking .Headercontainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: max-content;
    gap: 1rem;
    align-items: center;
    font-size: small;
  }
  .Booking .Headercontainer .Previous {
    display: flex;
    width: max-content;
    height: max-content;
  }
  .Booking .Headercontainer .Previous .Previousbtn {
    border-radius: 50%;
    margin-left: 0.3rem;
  }
  .Booking .Headercontainer .Previous .Previousbtn .Previousicon {
    width: 1rem;
    height: 1rem;
  }
  .Booking .Headercontainer .Header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    width: 100vw;
  }
  .Booking .Headercontainer .Header .Topheader {
    font-size: larger;
    font-weight: bold;
  }
  .Booking .Headercontainer .Header .Subheader {
    font-size: small;
    font-weight: bold;
    color: gray;
  }
  .Booking .Bodycontainer {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: max-content;
  }
  .Booking .Bodycontainer .Staffcontainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0rem;
    align-items: center;
    justify-content: center;
  }
  .Booking .Bodycontainer .Staffcontainer .Staffheader {
    font-size: large;
    font-weight: bold;
  }
  .Booking .Bodycontainer .Staffcontainer .Stafflist {
    display: flex;
    gap: 0rem;
    width: 95vw;
    margin: auto;
    flex-direction: row;
  }
  .Booking .Bodycontainer .Staffcontainer .Stafflist .Staff {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    border-radius: 12px;
    min-height: 7rem;
    margin: auto;
  }
  .Booking .Bodycontainer .Staffcontainer .Stafflist .Staff .Checkstaff {
    display: flex;
    align-self: center;
    width: max-content;
    height: max-content;
  }
  .Booking
    .Bodycontainer
    .Staffcontainer
    .Stafflist
    .Staff
    .Checkstaff
    .Radiobtn {
    width: 2rem;
    height: 2rem;
  }
  .Booking .Bodycontainer .Staffcontainer .Stafflist .Staff .Staffimage {
    display: flex;
    align-self: center;
    max-width: max-content;
    max-height: max-content;
  }
  .Booking .Bodycontainer .Staffcontainer .Stafflist .Staff .Staffimage .Image {
    width: 6rem;
    border-radius: 12px;
    object-fit: cover;
  }
  .Booking .Bodycontainer .Staffcontainer .Stafflist .Staff .Staffinfo {
    display: flex;
    flex-direction: column;

    gap: 0.5rem;
  }
  .Booking .Bodycontainer .Staffcontainer .Stafflist .Staff .Staffinfo .Name {
    font-size: large;
    font-weight: bold;
  }
  .Booking
    .Bodycontainer
    .Staffcontainer
    .Stafflist
    .Staff
    .Staffinfo
    .Occupation {
    font-size: medium;
    font-weight: bold;
    color: gray;
  }
  .Booking .Bodycontainer .Calendarcontainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1rem;
  }
  .Booking .Bodycontainer .Calendarcontainer .Calendarheader {
    font-size: large;
    font-weight: bold;
    margin: auto;
    margin-top: 5vh;
  }
  .Booking .Bodycontainer .Calendarcontainer .Booking_Stepper {
    width: 30%;
    align-self: center;
  }
  .Booking .Bodycontainer .Calendarcontainer .Calendarbody {
    display: flex;
    flex-direction: column;
    width: 99vw;
    align-items: center;
    min-height: 80vh;
    max-height: max-content;
    position: relative;
  }
  .Booking .Bodycontainer .Calendarcontainer .Calendarbody .Calendar_Bottom {
    margin-top: 3rem;
    margin-bottom: 4rem;
    background: #23a994;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Calendar_Bottom
    .Checkout {
    background-color: #23a994;
    font-size: larger;
    font-weight: bold;
  }
  .Booking .Bodycontainer .Calendarcontainer .Calendarbody .Viewchanger {
    position: absolute;
    top: 0;
    left: 1rem;
    font-size: medium;
  }
  .Booking .Bodycontainer .Calendarcontainer .Calendarbody .rdp {
    --rdp-cell-size: 14vw;
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
    --rdp-accent-color: #23a994;
  }
  .Booking .Bodycontainer .Calendarcontainer .Calendarbody .Weekcalendar {
    width: 98vw;
    min-height: 70vh;
    max-height: max-content;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 99vw;
    height: 3rem;
    align-items: center;
    font-weight: bold;
    font-size: smaller;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekheader
    .Leftheader
    .Prevbtn {
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
    font-size: 1rem;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekheader
    .Leftheader
    .Prevbtn
    .Btnicon {
    display: flex;
    align-self: center;
    width: 1.5rem;
    height: 1.5rem;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekheader
    .Rightheader
    .Nextbtn {
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
    font-size: 1rem;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekheader
    .Rightheader
    .Nextbtn
    .Btnicon {
    display: flex;
    align-self: center;
    width: 1.5rem;
    height: 1.5rem;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekbody {
    display: flex;
    flex-direction: row;
    width: 99vw;
    height: 100%;
    justify-content: space-between;
    border-right: 0.05px dotted #23a994;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekbody
    .Day {
    display: flex;
    flex-direction: column;
    border-left: 0.05px dotted #23a994;
    border-top: 0.05px dotted #23a994;
    border-bottom: 0.05px dotted #23a994;
    width: 14vw;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekbody
    .Day
    .Topday {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    justify-content: center;
    align-items: center;
    height: 8rem;
    font-weight: bold;
    font-size: large;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekbody
    .Day
    #Offday {
    background-color: #f7f7fa !important;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekbody
    .Day
    .Bottomday {
    padding-top: 1rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    height: 100%;
    font-size: smaller;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekbody
    .Day
    .Bottomday
    .Timeslot {
    display: flex;
    flex-direction: column;
    border: 1px solid #23a994;
    border-radius: 12px;
    padding: 0.5rem;
    font-weight: bold;
    width: 13.7vw;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .Booking
    .Bodycontainer
    .Calendarcontainer
    .Calendarbody
    .Weekcalendar
    .Weekbody
    .Day
    .Bottomday
    .TimeslotActive {
    display: flex;
    flex-direction: column;
    border: 1px solid #23a994;
    border-radius: 12px;
    padding: 0.5rem;
    font-weight: bold;
    width: 13.7vw;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #23a994;
    color: white;
  }
  .text_timeslot {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: small;
    position: absolute;
    z-index: 77;
    left: -4px;
    top: 0;
  }
  .Booking .mobile .Dayname,
  .Booking .Dayname .mobile {
    font-size: small;
  }

  .Booking .Daynumber {
    font-size: small;
  }
}
