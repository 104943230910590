.Reviews {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
}
.Reviews .Header {
  font-weight: bolder;
  font-size: large;
}
.Reviews .Ratingcontainer {
  background-color: white;
  border-radius: 12px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Reviews .Ratingcontainer .Reviewsnumbercontainer {
  display: flex;
  flex-direction: column;
}
.Reviews .Ratingcontainer .Reviewsnumbercontainer .Reviewsnumberheading {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.Reviews .Ratingcontainer .Reviewsnumbercontainer .Reviewsnumber {
  font-weight: bold;
  font-size: xx-large;
  color: #23a994;
}
.Reviews .Ratingcontainer .Ratingnumbercontainer {
  display: flex;
  flex-direction: column;
}
.Reviews .Ratingcontainer .Ratingnumbercontainer .Ratingheader {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.Reviews .Ratingcontainer .Ratingnumbercontainer .Ratingnumber {
  font-weight: bold;
  font-size: xx-large;
  color: #23a994;
}
.Progress {
  height: 0.5rem;
  align-items: center;
  margin-left: 0.3rem;
}
.Progress::-webkit-progress-bar {
  border-radius: 12px;
  background-color: white !important;
}
.Progress::-webkit-progress-value {
  border-radius: 12px;
  background-color: #23a994 !important;
}
.Ratingcontainer .Starscontainer .Progresscontainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start !important;
  align-content: flex-start !important;
  justify-content: flex-start !important;
  align-self: flex-start !important;
}
/***Reviews section***/
.Devider {
  margin-top: 1rem;
  height: 0.1rem;
  background-color: rgba(67, 55, 55, 0.495) !important;
}
.Reviewscontainer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.Reviewscontainer .Review {
  background-color: white;
  border-radius: 12px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.Reviewscontainer .Review .Infosectioncontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Reviewscontainer .Review .Infosectioncontainer .Infosection {
  display: flex;
  flex-direction: row;
}
.Reviewscontainer .Review .Infosectioncontainer .Infosection .profileinfo {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: small;
  align-self: center;
}
.Reviewscontainer
  .Review
  .Infosectioncontainer
  .Infosection
  .profileinfo
  .Review_section {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  
}
.Reviewscontainer
  .Review
  .Infosectioncontainer
  .Infosection
  .profileinfo
  .profilename {
  font-weight: bold;
}
.Reviewscontainer .Review .Reviewtxt {
  margin-top: 0.5rem;
}
.Starscontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
}
