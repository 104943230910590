.Fergotpass {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}
.Fergotpass .Fergotpasscontainer {
  display: flex;
  flex: 0.6;
  flex-direction: column;
}
.Fergotpass .Loginright {
  display: flex;
  flex: 0.4;
}
.Fergotpass .Fergotpasscontainer .Logocontainer {
  width: 13rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.Fergotpass .Fergotpasscontainer .Header {
  margin-left: 6.5rem;
  font-weight: 600;
  font-size: xx-large;
  margin-top: 4rem;
}
.Fergotpass .Fergotpasscontainer .Subheader {
  margin-left: 6.5rem;
  margin-top: 2rem;
  color: #4d4d4d;
}
.Fergotpass .Fergotpasscontainer .Label {
  margin-left: 6.5rem;
  margin-top: 4rem;
}
.Fergotpass .Fergotpasscontainer .Emailinput {
  width: 30rem;
  margin-left: 6.5rem;
  margin-top: 1rem;
}
.Fergotpass .Fergotpasscontainer .Submitbtn {
  width: 30rem;
  margin-left: 6.5rem;
  margin-top: 2rem;
  background-color: #23a994;
}
@media (max-width: 1400px) {
  .Fergotpass .Fergotpasscontainer {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Fergotpass .Loginright {
    display: flex;
    flex: 0.45;
  }
  .Fergotpass .Fergotpasscontainer .Logocontainer {
    width: 13rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .Fergotpass .Fergotpasscontainer .Header {
    margin-left: 4rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }
  .Fergotpass .Fergotpasscontainer .Subheader {
    margin-left: 4rem;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Fergotpass .Fergotpasscontainer .Label {
    margin-left: 4rem;
    margin-top: 4rem;
  }
  .Fergotpass .Fergotpasscontainer .Emailinput {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 1rem;
  }
  .Fergotpass .Fergotpasscontainer .Submitbtn {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 2rem;
    background-color: #23a994;
  }
}
@media (max-width: 1100px) {
  .Fergotpass .Fergotpasscontainer {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Fergotpass .Loginright {
    display: flex;
    flex: 0.45;
  }
  .Fergotpass .Fergotpasscontainer .Logocontainer {
    width: 13rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .Fergotpass .Fergotpasscontainer .Header {
    margin-left: 2rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }
  .Fergotpass .Fergotpasscontainer .Subheader {
    margin-left: 2rem;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Fergotpass .Fergotpasscontainer .Label {
    margin-left: 2rem;
    margin-top: 4rem;
  }
  .Fergotpass .Fergotpasscontainer .Emailinput {
    width: 30rem;
    margin-left: 2rem;
    margin-top: 1rem;
  }
  .Fergotpass .Fergotpasscontainer .Submitbtn {
    width: 30rem;
    margin-left: 2rem;
    margin-top: 2rem;
    background-color: #23a994;
  }
}
@media (max-width: 960px) {
  .Fergotpass .Fergotpasscontainer {
    display: flex;
    flex: 0.55;
    flex-direction: column;
  }
  .Fergotpass .Loginright {
    display: none;
  }
  .Fergotpass .Fergotpasscontainer .Logocontainer {
    width: 13rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .Fergotpass .Fergotpasscontainer .Header {
    margin-left: 4rem;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
  }
  .Fergotpass .Fergotpasscontainer .Subheader {
    margin-left: 4rem;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Fergotpass .Fergotpasscontainer .Label {
    margin-left: 4rem;
    margin-top: 4rem;
  }
  .Fergotpass .Fergotpasscontainer .Emailinput {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 1rem;
  }
  .Fergotpass .Fergotpasscontainer .Submitbtn {
    width: 30rem;
    margin-left: 4rem;
    margin-top: 2rem;
    background-color: #23a994;
  }
}
@media (max-width: 560px) {
  .Fergotpass .Fergotpasscontainer {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .Fergotpass .Loginright {
    display: none;
  }
  .Fergotpass .Fergotpasscontainer .Logocontainer {
    width: 13rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .Fergotpass .Fergotpasscontainer .Header {
    width: 95vw;
    margin: auto;
    /* margin-left: 4rem; */
    font-weight: 600;
    font-size: xx-large;
    margin-top: 4rem;
    margin-bottom: 0rem;
  }
  .Fergotpass .Fergotpasscontainer .Subheader {
    width: 95vw;
    margin: auto;
    margin-bottom: 0rem;
    margin-top: 2rem;
    color: #4d4d4d;
  }
  .Fergotpass .Fergotpasscontainer .Label {
    width: 95vw;
    margin: auto;
    margin-top: 4rem;
    margin-bottom: 0;
  }
  .Fergotpass .Fergotpasscontainer .Emailinput {
    width: 95vw;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .Fergotpass .Fergotpasscontainer .Submitbtn {
    width: 95vw;
    margin: auto;
    margin-top: 2rem;
    background-color: #23a994;
  }
}
